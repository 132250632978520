import {
  ALL_DISCUSSIONS_CHANGED,
  CURRENT_DISCUSSION_CHANGED,
} from "../constants";

const initialState = {
  allDiscussions: [],
  currentDiscussion: null,
};

export const discussion = (state = initialState, action) => {
  switch (action.type) {
    case ALL_DISCUSSIONS_CHANGED:
      return {
        ...state,
        allDiscussions: action.allDiscussions,
      };
    case CURRENT_DISCUSSION_CHANGED:
      return {
        ...state,
        currentDiscussion: action.currentDiscussion,
      };
    default:
      return state;
  }
};
