import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HorizontalLine from "../components/general/HorizontalLine";
import InfoCardRow from "../components/general/InfoCardRow";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import { getAllDiscussions } from "../store/actions/discussion";
import FooterSection from "./FooterSection";

export default function DiscussionSection() {
  // const ref = useRef();
  // const isVisible = useIsVisible(ref);

  const discussions = useSelector((state) => state.discussion.allDiscussions);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isVisible) {
  //     setVisibleSection("discussion");
  //
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isVisible]);

  useEffect(() => {
    dispatch(getAllDiscussions());
  }, [dispatch]);

  return (
    <div className="relative overflow-hidden">
      <NavBar />
      <div id="fachdiskussion" className="w-full">
        <div className="pt-32">
          <Title text="Fachdiskussion" />
          <HorizontalLine />
        </div>
        <div className="px-4 lg:px-16">
          {/* <div ref={ref}></div> */}
          {discussions !== undefined && discussions.length !== 0 ? (
            discussions.map((discussion) => (
              <a href={"discussion/" + discussion.docId} key={discussion.id}>
                <InfoCardRow
                  title={discussion.title}
                  author={
                    discussion.authorFirstName + " " + discussion.authorLastName
                  }
                  category="Wissen"
                  info={discussion.content}
                  key={discussion.id}
                />
              </a>
            ))
          ) : (
            <p className="text-slate-900 text-center mx-6 lg:mx-96">
              Es sind derzeit keine Diskussionen vorhanden!
            </p>
          )}
        </div>
      </div>
      <FooterSection />
    </div>
  );
}
