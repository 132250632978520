import React from "react";
import HorizontalLine from "../components/general/HorizontalLine";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import FooterSection from "./FooterSection";

export default function DataProtectionSection() {
  return (
    <div className="relative w-full">
      <NavBar />
      <div className="w-full pt-32">
        <Title text="Datenschutz" />
        <HorizontalLine />
      </div>
      <FooterSection />
    </div>
  );
}
