import React, { useState } from "react";
import { useSelector } from "react-redux";
import AdminHomeSection from "../sections/AdminHomeSection";
import AddDiscussionSection from "../sections/discussion/AddDiscussionSection";
import ManageDiscussionSection from "../sections/discussion/ManageDiscussionSection";
import AddJobSection from "../sections/jobs/AddJobsSection";
import ManageJobsSection from "../sections/jobs/ManageJobsSection";
import LoginSection from "../sections/LoginSection";
import SignupSection from "../sections/SignupSection";
import AddOfferSection from "../sections/offers/AddOfferSection";
import ManageOfferSection from "../sections/offers/ManageDiscussionSection";
import AddUkraineSection from "../sections/ukraine/AddUkraineSection";
import ManageUkraineSection from "../sections/ukraine/ManageUkraineSection";

export default function AdminScreen() {
  const [openSection, setOpenSection] = useState("home");
  const [updateProperties, setUpdateProperties] = useState();

  const currentUser = useSelector((state) => state.auth.currentUser);

  const handleSection = () => {
    switch (openSection) {
      case "addDiscussion":
        return <AddDiscussionSection setOpenSection={setOpenSection} />;
      case "manageDiscussion":
        return (
          <ManageDiscussionSection
            setOpenSection={setOpenSection}
            setUpdateProperties={setUpdateProperties}
          />
        );
      case "updateDiscussion":
        return (
          <AddDiscussionSection
            setOpenSection={setOpenSection}
            update={true}
            contentUpdate={updateProperties.content}
            titleUpdate={updateProperties.title}
            thumbnailUpdate={updateProperties.thumb}
            docId={updateProperties.id}
            filesUpdate={updateProperties.files}
            firstnameUpdate={updateProperties.authorFirstName}
            lastnameUpdate={updateProperties.authorLastName}
          />
        );
      case "addUkraine":
        return <AddUkraineSection setOpenSection={setOpenSection} />;
      case "manageUkraine":
        return (
          <ManageUkraineSection
            setOpenSection={setOpenSection}
            setUpdateProperties={setUpdateProperties}
          />
        );
      case "updateUkraine":
        return (
          <AddUkraineSection
            setOpenSection={setOpenSection}
            update={true}
            contentUpdate={updateProperties.content}
            titleUpdate={updateProperties.title}
            thumbnailUpdate={updateProperties.thumb}
            docId={updateProperties.id}
            filesUpdate={updateProperties.files}
          />
        );
      case "updateJob":
        return (
          <AddJobSection
            setOpenSection={setOpenSection}
            update={true}
            descriptionUpdate={updateProperties.description}
            titleUpdate={updateProperties.title}
            workingAreaUpdate={updateProperties.workingArea}
            docId={updateProperties.id}
            workingStartUpdate={updateProperties.workingStart}
            tagUpdate={updateProperties.tag}
            qualificationUpdate={updateProperties.qualification}
            locationUpdate={updateProperties.location}
            jobTypeUpdate={updateProperties.jobType}
          />
        );
      case "updateOffer":
        return (
          <AddOfferSection
            setOpenSection={setOpenSection}
            update={true}
            contentUpdate={updateProperties.content}
            titleUpdate={updateProperties.title}
            tagUpdate={updateProperties.tag}
            thumbnailUpdate={updateProperties.thumb}
            docId={updateProperties.id}
            filesUpdate={updateProperties.files}
          />
        );
      case "addJob":
        return (
          <AddJobSection
            setOpenSection={setOpenSection}
            setUpdateProperties={setUpdateProperties}
          />
        );
      case "manageJobs":
        return (
          <ManageJobsSection
            setOpenSection={setOpenSection}
            setUpdateProperties={setUpdateProperties}
          />
        );
      case "addOffer":
        return <AddOfferSection setOpenSection={setOpenSection} />;
      case "manageOffers":
        return (
          <ManageOfferSection
            setOpenSection={setOpenSection}
            setUpdateProperties={setUpdateProperties}
          />
        );
      default:
        return <AdminHomeSection setOpenSection={setOpenSection} />;
    }
  };

  return (
    <div className="w-full overflow-hidden">
      {currentUser !== null ? handleSection() : <LoginSection />}
    </div>
  );
}
