import cuid from "cuid";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileGrid from "../../components/general/FileGrid";
import FileInput from "../../components/general/FileInput";
import GeneralButton from "../../components/general/GeneralButton";
import SmoothTextInput from "../../components/general/SmoothTextInput";
import Spinner from "../../components/general/Spinner";
import Title from "../../components/general/Title";
import {
  createUkraine,
  updateUkraineUpload,
} from "../../store/actions/ukraine";

export default function AddUkraineSection({
  setOpenSection,
  update = false,
  titleUpdate = "",
  contentUpdate = "",
  thumbnailUpdate = "",
  docId = "",
  filesUpdate = [],
}) {
  const [title, setTitle] = useState(titleUpdate);
  const [content, setContent] = useState(contentUpdate);
  const [thumbnail, setThumbnail] = useState(thumbnailUpdate);
  const [fromStorageFiles, setFromStorageFiles] = useState(filesUpdate);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const currentUser = useSelector((state) => state.auth.currentUser);

  const dispatch = useDispatch();

  const resetInputs = () => {
    setTitle("");
    setContent("");
    setThumbnail("");
    setFiles([]);
  };

  const handleUploadUkraine = () => {
    setLoading(true);
    if (thumbnail === "") {
      setLoading(false);
      setError("Bitte wählen Sie ein Foto aus.");
      return;
    }

    if (title === "") {
      setLoading(false);
      setError("Bitte wählen Sie einen Titel");
      return;
    }

    if (content === "") {
      setLoading(false);
      setError("Bitte legen Sie den Inhalt fest.");
      return;
    }

    update
      ? updateUkraine()
      : dispatch(
          createUkraine(
            title,
            content,
            thumbnail,
            currentUser.firstname,
            currentUser.lastname,
            files
          )
        )
          .then((id) => {
            resetInputs();
            setLoading(false);
            window.location.assign("/ukraine/" + id);
          })
          .catch((err) => {
            setLoading(false);

            setError(
              "Etwas ist schief gelaufen, bitte versuchen Sie es erneut."
            );
          });
  };

  const updateUkraine = () => {
    let updateDic = {};
    if (title !== titleUpdate) {
      updateDic["title"] = title;
    }
    if (thumbnail !== thumbnailUpdate) {
      updateDic["thumbnail"] = thumbnail;
    }
    if (content !== contentUpdate) {
      updateDic["content"] = content;
    }
    if (fromStorageFiles !== filesUpdate) {
      updateDic["files"] = fromStorageFiles;
    }
    if (files.length !== 0) {
      updateDic["newFiles"] = files;
    }

    if (Object.keys(updateDic).length !== 0) {
      dispatch(updateUkraineUpload(docId, updateDic))
        .then((id) => {
          resetInputs();
          setLoading(false);
          setOpenSection("manageUkraine");
        })
        .catch((err) => {
          setLoading(false);
          setError("Etwas ist schief gelaufen, bitte versuchen Sie es erneut.");
        });
    } else {
      setOpenSection("manageUkraine");
    }
  };

  const removeFileById = (id, fromStorage = false) => {
    fromStorageFiles.forEach((file) => console.log(id + " - " + file.src));
    let _newFiles = fromStorage
      ? fromStorageFiles.filter((file) => !(file.src === id))
      : files.filter((file) => !(file.id === id));
    fromStorage ? setFromStorageFiles(_newFiles) : setFiles(_newFiles);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setThumbnail(file);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const onDropFiles = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFiles((prevState) => [...prevState, { id: cuid(), src: file }]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center align-middle">
      <Title text="Neues Ukraine Thema erstellen" />
      {update && (
        <>
          <Title text="Bisheriges Foto" size="small" />
          <img
            src={thumbnailUpdate}
            alt=""
            className="w-96 object-cover rounded mb-2"
          />
        </>
      )}
      <FileInput
        onDrop={onDrop}
        accept={"image/*"}
        label={
          update
            ? "Laden Sie hier ein neues Foto hoch"
            : "Laden Sie ein Foto hoch"
        }
      />
      <div className="w-full px-4 lg:px-48">
        <SmoothTextInput
          label="Titel des Themas"
          setValue={setTitle}
          value={title}
          placeholder="Geben Sie einen passenden Titel ein."
        />
        <SmoothTextInput
          label="Inhalt des Themas"
          setValue={setContent}
          value={content}
          placeholder="Kopieren Sie den Inhalt der Ukraine hier hinein."
          multiline={true}
        />
      </div>
      <FileInput
        onDrop={onDropFiles}
        accept={""}
        label={
          update
            ? "Laden Sie hier weitere Dateien zum Download hoch."
            : "Laden Sie hier Dateien zum Download hoch."
        }
        enableFilePreview={false}
      />
      <FileGrid
        files={fromStorageFiles}
        removeFilesById={removeFileById}
        fromStorage={true}
      />
      <FileGrid files={files} removeFilesById={removeFileById} />
      {error !== "" && <p className="text-red-400 font-semibold">{error}</p>}
      {loading ? (
        <div className="w-full flex justify-center my-2 mb-4">
          <Spinner />
        </div>
      ) : (
        <>
          <GeneralButton
            label={
              !update ? "Ukraine Thema hinzufügen" : "Ukraine Thema aktualisieren"
            }
            onClick={() => handleUploadUkraine()}
          />
          <GeneralButton
            label="Abbrechen"
            color="blue"
            onClick={() => setOpenSection("home")}
          />
        </>
      )}
    </div>
  );
}
