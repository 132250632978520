import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HorizontalLine from "../components/general/HorizontalLine";
import JobCard from "../components/general/JobCard";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import FooterSection from "../sections/FooterSection";
import { getAllJobs } from "../store/actions/jobs";

export default function JobsScreen() {
  // const ref = useRef();
  // const isVisible = useIsVisible(ref);

  const jobs = useSelector((state) => state.jobs.allJobs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllJobs());
  }, [dispatch]);

  // useEffect(() => {
  //   if (isVisible) {
  //     setVisibleSection("jobs");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isVisible]);
  return (
    <div className="relative overflow-hidden">
      <NavBar />
      <div
        id="jobs"
        className="w-full flex items-center bg-gray-50 flex-col overflow-hidden"
      >
        <div className="pt-32">
          <Title text="Stellenausschreibungen" />
          <HorizontalLine />
        </div>
        {/* <div ref={ref}></div> */}
        {jobs !== undefined && jobs.length !== 0 ? (
          jobs.map((job) => (
            <JobCard
              jobTitle={job.title}
              location={job.location !== undefined ? job.location : "Gera"}
              qualification={job.qualification}
              time={job.jobType}
              workingArea={job.workingArea}
              type={job.tag}
              workingStart={job.workingStart}
              id={job.docId}
              key={job.id}
            />
          ))
        ) : (
          <p className="text-slate-900 text-center mx-96">
            Es sind derzeit keine Stellenausschreibungen vorhanden!
          </p>
        )}
      </div>
      <FooterSection marginTop={false} />
    </div>
  );
}
