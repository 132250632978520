import React, { useState } from "react";
import NavBar from "../navigation/NavBar";
import FooterSection from "../sections/FooterSection";
import HeroSection from "../sections/HeroSection";

export default function HomeScreen() {
  //const scrollRef = useRef(null);
  const [visibleSection, setVisibleSection] = useState("");

  //useScrollSnap({ ref: scrollRef, duration: 5, delay: 5 });

  return (
    <div className="relative overflow-hidden">
      <NavBar visibleSection={visibleSection} />

      <main>
        <section>
          <HeroSection setVisibleSection={setVisibleSection} />
          {/* <YouthWelfareOfficeScreen setVisibleSection={setVisibleSection} />
          <JobsScreen setVisibleSection={setVisibleSection} />
          <DiscussionSection setVisibleSection={setVisibleSection} />
          <ProviderSection setVisibleSection={setVisibleSection} />
          <ContactSection setVisibleSection={setVisibleSection} /> */}
        </section>
      </main>
      <FooterSection marginTop={false} />
    </div>
  );
}
