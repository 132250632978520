import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Dropwdown from "../../components/general/Dropwdown";
import GeneralButton from "../../components/general/GeneralButton";
import SmoothTextInput from "../../components/general/SmoothTextInput";
import Spinner from "../../components/general/Spinner";
import Title from "../../components/general/Title";
import { createJob, updateJobUpload } from "../../store/actions/jobs";

const JobTypeItem = (value, removeItem) => (
  <button
    onClick={() => removeItem(value)}
    className="bg-yellow-400 px-4 py-2 text-white rounded flex flex-row w-40 my-2 justify-between align-middle items-center hover:text-yellow-400 hover:bg-white hover:border hover:border-solid hover:border-yellow-400 transition-colors"
  >
    <p>{value}</p>
    <MdDelete size={24} />
  </button>
);

export default function AddJobSection({
  setOpenSection,
  update = false,
  titleUpdate = "",
  descriptionUpdate = "",
  jobTypeUpdate = ["Vollzeit"],
  workingAreaUpdate = "",
  workingStartUpdate = "",
  qualificationUpdate = "",
  locationUpdate = "",
  tagUpdate = "",
  docId = "",
}) {
  const [title, setTitle] = useState(titleUpdate);
  const [description, setDescription] = useState(descriptionUpdate);
  const [jobType, setJobType] = useState(jobTypeUpdate);
  const [workingArea, setWorkingArea] = useState(workingAreaUpdate);
  const [workingStart, setWorkingStart] = useState(workingStartUpdate);
  const [qualification, setQualification] = useState(qualificationUpdate);
  const [location, setLocation] = useState(locationUpdate);
  const [tag, setTag] = useState(tagUpdate);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const currentUser = useSelector((state) => state.auth.currentUser);

  const dispatch = useDispatch();

  const jobTypes = [
    "Vollzeit",
    "Teilzeit",
    "Duales Studium",
    "Freie Arbeit/Honorar",
  ];

  const resetInputs = () => {
    setTitle("");
    setDescription("");
    setWorkingArea("");
    setWorkingStart("");
    setJobType(["Vollzeit"]);
    setTag("");
    setQualification("");
  };

  const addJobType = (value) => {
    if (!jobType.includes(value)) {
      setJobType((prevState) => [...prevState, value]);
    }
  };

  const removeJobType = (value) => {
    const _newJobTypes = jobType.filter((job) => !(value === job));
    setJobType(_newJobTypes);
  };

  const handleUploadJob = () => {
    setLoading(true);
    if (title === "") {
      setError("Bitte legen Sie einen Titel fest.");
      setLoading(false);
      return;
    }

    if (description === "") {
      setError("Bitte legen Sie eine Arbeitsbeschreibung fest.");
      setLoading(false);
      return;
    }

    if (tag === "") {
      setError("Bitte legen Sie ein Schlagwort fest.");
      setLoading(false);
      return;
    }

    if (workingArea === "") {
      setError("Bitte legen Sie mind. einen Arbeitsbereich fest.");
      setLoading(false);
      return;
    }

    if (workingStart === "") {
      setError("Bitte legen Sie den Arbeitsbeginn fest.");
      setLoading(false);
      return;
    }

    if (qualification === "") {
      setError("Bitte legen Sie mind. einen Berufsabschluss fest.");
      setLoading(false);
      return;
    }

    if (location === "") {
      setError("Bitte legen Sie den Standort fest.");
      setLoading(false);
      return;
    }

    if (jobType === "") {
      setError("Bitte wählen Sie mind. eine Jobart fest.");
      setLoading(false);
      return;
    }

    update
      ? updateJob()
      : dispatch(
          createJob(
            title,
            description,
            tag,
            workingArea,
            jobType,
            workingStart,
            qualification,
            location,
            currentUser.firstname,
            currentUser.lastname
          )
        )
          .then((id) => {
            resetInputs();
            setLoading(false);
            window.location.assign("/job/" + id);
          })
          .catch((err) => {
            setLoading(false);
            setError(
              "Etwas ist schiefgelaufen, bitte versuchen Sie es erneut."
            );
          });
  };

  const updateJob = () => {
    let updateDic = {};
    if (title !== titleUpdate) {
      updateDic["title"] = title;
    }
    if (description !== descriptionUpdate) {
      updateDic["description"] = description;
    }
    if (jobType !== jobTypeUpdate) {
      updateDic["jobType"] = jobType;
    }
    if (workingArea !== workingAreaUpdate) {
      updateDic["workingArea"] = workingArea;
    }
    if (workingStart !== workingStartUpdate) {
      updateDic["workingStart"] = workingStart;
    }
    if (qualification !== qualificationUpdate) {
      updateDic["qualification"] = qualification;
    }
    if (location !== locationUpdate) {
      updateDic["location"] = location;
    }
    if (tag !== tagUpdate) {
      updateDic["tag"] = tag;
    }

    if (Object.keys(updateDic).length !== 0) {
      dispatch(updateJobUpload(docId, updateDic))
        .then((id) => {
          resetInputs();
          setLoading(false);
          setOpenSection("manageJobs");
        })
        .catch((err) => {
          setLoading(false);
          setError("Etwas ist schief gelaufen, bitte versuchen Sie es erneut.");
        });
    } else {
      setOpenSection("manageJobs");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center align-middle">
      <Title text="Neuen Job erstellen" />
      <div className="w-full px-4 lg:px-48">
        <SmoothTextInput
          label="Titel der Stellenausschreibung"
          setValue={setTitle}
          value={title}
          placeholder="Geben Sie den Titel/Überschrift der Stellenausschreibungen ein."
        />
        <SmoothTextInput
          label="Schlagwort"
          setValue={setTag}
          value={tag}
          placeholder="Legen Sie ein Schlagwort für die Stellenausschreibung fest"
        />
        <SmoothTextInput
          label="Arbeitsbeschreibung"
          setValue={setDescription}
          value={description}
          placeholder="Kopieren Sie die Beschreibung für die Arbeit hier hinein."
          multiline={true}
        />
        <SmoothTextInput
          label="Arbeitsbereiche"
          setValue={setWorkingArea}
          value={workingArea}
          placeholder="Welche Bereiche umfasst dieser Job? Kommasepariert."
        />
        <SmoothTextInput
          label="Anstellungsbeginn"
          setValue={setWorkingStart}
          value={workingStart}
          placeholder="z.B. Ab sofort"
        />
        <SmoothTextInput
          label="Berufsabschluss"
          setValue={setQualification}
          value={qualification}
          placeholder="Welche Berufsabschlüsse sind vorausgesetzt? Kommaspepariert."
        />
        <SmoothTextInput
          label="Standort"
          setValue={setLocation}
          value={location}
          placeholder="Geben Sie den Standort an."
        />
        <Dropwdown
          title="Wählen Sie die Art des Jobs"
          options={jobTypes}
          setValue={addJobType}
          value={jobType}
        />
        <div className="grid grid-cols-2 gap-15 w-96">
          {jobType.map((job) => JobTypeItem(job, removeJobType))}
        </div>
        {error !== "" && <p className="text-red-400 font-semibold">{error}</p>}
      </div>
      {loading ? (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <GeneralButton
            label="Job hinzufügen"
            onClick={() => handleUploadJob()}
          />
          <GeneralButton
            label="Abbrechen"
            color="blue"
            onClick={() => setOpenSection("home")}
          />
        </>
      )}
    </div>
  );
}
