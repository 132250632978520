import firebase from "firebase/app";
import { saveMediaToStorage } from "../../services/random";
import uuid from "uuid-random";
import { All_OFFERS_CHANGED, CURRENT_OFFER_CHANGED } from "../constants";
require("firebase/firebase-auth");
require("firebase/firestore");

export const createOffers =
  (title, content, thumbnail, authorFirstName, authorLastName, files, tag) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const uploadDate = new Date();
      let storagePostId = uuid() + uploadDate.getMilliseconds();
      saveMediaToStorage(
        thumbnail,
        `offers/${firebase.auth().currentUser.uid}/${storagePostId}/thumbnail`
      )
        .then((thumb) => {
          firebase
            .firestore()
            .collection("offers")
            .doc(storagePostId)
            .set({
              id: storagePostId,
              author: firebase.auth().currentUser.uid,
              authorFirstName,
              authorLastName,
              title,
              content,
              thumb,
              uploadDate,
              updatedDate: uploadDate,
              files: [],
              tag,
            })
            .then(() => {
              let index = 0;
              files !== undefined && files.length !== 0
                ? files.forEach((file) => {
                    index++;
                    index === files.length
                      ? addFileToOffer(file.src, storagePostId).then(() =>
                          resolve(storagePostId)
                        )
                      : addFileToOffer(file.src, storagePostId);
                  })
                : resolve(storagePostId);
            })
            .catch((e) => reject(e));
        })
        .catch((err) => reject());
    });

export const updateOfferUpload = (docId, updateDic) => (dispatch) =>
  new Promise((resolve, reject) => {
    const newFiles = updateDic.newFiles;
    delete updateDic["newFiles"];
    updateDic.thumbnail === null || updateDic.thumbnail === undefined
      ? firebase
          .firestore()
          .collection("offers")
          .doc(docId)
          .update(updateDic)
          .then(() => {
            let index = 0;
            newFiles !== undefined && newFiles !== null && newFiles.length !== 0
              ? newFiles.forEach((file) => {
                  index++;
                  index === newFiles.length
                    ? addFileToOffer(file.src, docId).then(() => resolve(docId))
                    : addFileToOffer(file.src, docId);
                })
              : resolve(docId);
          })
          .catch((e) => reject(e))
      : saveMediaToStorage(
          updateDic.thumbnail,
          `offers/${firebase.auth().currentUser.uid}/${docId}/thumbnail`
        )
          .then((thumb) => {
            delete updateDic["thumbnail"];
            firebase
              .firestore()
              .collection("offers")
              .doc(docId)
              .update({ thumb, ...updateDic })
              .then(() => {
                let index = 0;
                newFiles !== undefined &&
                newFiles !== null &&
                newFiles.length !== 0
                  ? newFiles.forEach((file) => {
                      index++;
                      index === newFiles.length
                        ? addFileToOffer(file.src, docId).then(() =>
                            resolve(docId)
                          )
                        : addFileToOffer(file.src, docId);
                    })
                  : resolve(docId);
              })
              .catch((e) => reject(e));
          })
          .catch((err) => reject());
  });
export const addFileToOffer = (file, id) =>
  new Promise((resolve, reject) => {
    saveMediaToStorage(
      file,
      `offers/${firebase.auth().currentUser.uid}/${id}/${file.path}`
    ).then((f) => {
      firebase
        .firestore()
        .collection("offers")
        .doc(id)
        .update({
          files: firebase.firestore.FieldValue.arrayUnion({
            src: f,
            name: file.path,
          }),
        })
        .then(() => resolve())
        .catch((e) => reject(e));
    });
  });
export const getAllOffers = () => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("offers")
      .orderBy("uploadDate", "desc")
      .onSnapshot((snapshot) => {
        let offers = snapshot.docs.map((doc) => {
          const data = doc.data();
          const docId = doc.id;
          return { docId, ...data };
        });
        dispatch({
          type: All_OFFERS_CHANGED,
          allOffers: offers,
        });
      });
  });

export const deleteOfferByDocId = (docId) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("offers")
      .doc(docId)
      .delete()
      .then(() => resolve())
      .catch(() => reject());
  });

export const getOfferByDocId = (docId) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("offers")
      .doc(docId)
      .onSnapshot((snapshot) => {
        dispatch({
          type: CURRENT_OFFER_CHANGED,
          currentOffer: { docId, ...snapshot.data() },
        });
      });
  });
