import React, { useEffect, useRef } from "react";
import { useIsVisible } from "../functions/customHooks";

export default function HeroSection({ setVisibleSection }) {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (isVisible) {
      setVisibleSection("start");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div id="start" className="h-screen w-full flex items-center relative">
      <img
        src="./assets/images/background.jpeg"
        className="object-cover w-full h-full absolute z-0"
        alt=""
      />
      <div className="px-2 lg:ml-10 z-10 mt-24">
        <div ref={ref}></div>
        <p className="text-6xl text-blue-200 font-semibold text-start">
          Jugendhilfe Most
        </p>
        <p className="text-4xl text-slate-50 py-2">
          Individualpädagogische Jugendhilfe
        </p>
        <a
          href="/kontakt"
          className="inline-block w-full my-4 px-4 py-2 text-center font-semibold text-white bg-blue-300 hover:text-blue-300 hover:bg-white hover:border hover:border-blue-300 transition-colors rounded-md shadow"
        >
          Jetzt kontaktieren
        </a>
      </div>
    </div>
  );
}
