import { ALL_JOBS_CHANGED, CURRENT_JOB_CHANGED } from "../constants";

const initialState = {
  allJobs: [],
  currentJob: null,
};

export const jobs = (state = initialState, action) => {
  switch (action.type) {
    case ALL_JOBS_CHANGED:
      return {
        ...state,
        allJobs: action.allJobs,
      };
    case CURRENT_JOB_CHANGED:
      return {
        ...state,
        currentJob: action.currentJob,
      };
    default:
      return state;
  }
};
