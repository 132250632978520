import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SmoothTextInput from "../components/general/SmoothTextInput";
import Spinner from "../components/general/Spinner";
import Title from "../components/general/Title";
import { getCurrentUserData, login } from "../store/actions/auth";

export default function LoginSection() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLogin = () => {
    setError("");
    setLoading(true);
    dispatch(login(email.replace(/ /g, ""), password))
      .then(() => {
        dispatch(getCurrentUserData())
          .then(() => {
            window.location.href("/admin");
          })
          .catch((error) => {
            setLoading(false);
            setError((prevState) => prevState + error);
          });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  return (
    <div className="w-full h-screen flex items-center justify-center align-middle">
      <div className="justify-center items-center w-full shadow rounded-lg bg-white px-6 flex flex-col md:w-1/2 lg:w-1/3 m-auto">
        <Title text="Login" />
        <SmoothTextInput
          type="email"
          placeholder="example@email.de"
          label="Email"
          setValue={setEmail}
          value={email}
        />
        <SmoothTextInput
          setValue={setPassword}
          value={password}
          type="password"
          label="Passwort"
          placeholder="Geben Sie Ihr Passwort ein."
        />
        {error !== "" && <p className="text-blue-400 my-1">{error}</p>}
        {loading ? (
          <div className="py-2">
            <Spinner />
          </div>
        ) : (
          <button
            value="button"
            onClick={() => handleLogin()}
            className="px-4 py-2 rounded bg-yellow-400 text-white hover:bg-yellow-700 my-4 w-full"
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
}
