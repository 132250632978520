import {
    ALL_UKRAINE_CHANGED,
    CURRENT_UKRAINE_CHANGED,
  } from "../constants";
  
  const initialState = {
    allUkraine: [],
    currentUkraine: null,
  };
  
  export const ukraine = (state = initialState, action) => {
    switch (action.type) {
      case ALL_UKRAINE_CHANGED:
        return {
          ...state,
          allUkraine: action.allUkraine,
        };
      case CURRENT_UKRAINE_CHANGED:
        return {
          ...state,
          currentUkraine: action.currentUkraine,
        };
      default:
        return state;
    }
  };
  