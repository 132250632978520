import { combineReducers } from "redux";
import { auth } from "./auth";
import { discussion } from "./discussion";
import { jobs } from "./jobs";
import { offers } from "./offers";
import { ukraine } from "./ukraine";

const Reducers = combineReducers({
  auth,
  discussion,
  jobs,
  offers,
  ukraine,
});

export default Reducers;
