import React from "react";
import Title from "./Title";

export default function TextSection({ title = "", text }) {
  return (
    <div className="py-2">
      {title !== "" && <Title text={title} size="small" />}
      <p className="text-start">{text}</p>
    </div>
  );
}
