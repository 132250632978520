import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoCardRow from "../../components/general/InfoCardRow";
import Title from "../../components/general/Title";
import {
  deleteDiscussionByDocId,
  getAllDiscussions,
} from "../../store/actions/discussion";
import { MdHome } from "react-icons/md";

export default function ManageDiscussionSection({
  setOpenSection,
  setUpdateProperties,
}) {
  const discussions = useSelector((state) => state.discussion.allDiscussions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDiscussions());
  }, [dispatch]);

  const deleteDiscussion = (docId) => {
    dispatch(deleteDiscussionByDocId(docId)).then(() =>
      dispatch(getAllDiscussions())
    );
  };

  const updateDiscussion = (discussion) => {
    setOpenSection("updateDiscussion");
    setUpdateProperties(discussion);
  };

  return (
    <div className="px-4 lg:px-10">
      <div className="flex flex-row w-full justify-center">
        <Title text="Diskussionen verwalten" />
        <button
          className="text-slate-900 hover:text-slate-600 transition-colors"
          onClick={() => setOpenSection("home")}
        >
          <MdHome size={42} />
        </button>
      </div>
      {discussions.length !== 0 ? (
        discussions.map((discussion) => (
          <InfoCardRow
            title={discussion.title}
            author={
              discussion.authorFirstName + " " + discussion.authorLastName
            }
            category="Wissen"
            info={discussion.content}
            admin={true}
            onDeleteClick={() => deleteDiscussion(discussion.docId)}
            onUpdateClick={() => updateDiscussion(discussion)}
            key={discussion.id}
          />
        ))
      ) : (
        <p className="text-slate-900 text-center mx-96">
          Es sind derzeit keine Diskussionen vorhanden!
          <button
            className="ml-3 hover:text-slate-600 transition-colors"
            href=""
            onClick={() => setOpenSection("addDiscussion")}
          >
            Klicke hier um eine zu erstellen.
          </button>
        </p>
      )}
    </div>
  );
}
