import firebase from "firebase/app";
import uuid from "uuid-random";
import { ALL_JOBS_CHANGED, CURRENT_JOB_CHANGED } from "../constants";
require("firebase/firebase-auth");
require("firebase/firestore");

export const createJob =
  (
    title,
    description,
    tag,
    workingArea,
    jobType,
    workingStart,
    qualification,
    location,
    authorFirstName,
    authorLastName
  ) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const uploadDate = new Date();
      let storagePostId = uuid() + uploadDate.getMilliseconds();
      firebase
        .firestore()
        .collection("jobs")
        .doc(storagePostId)
        .set({
          id: storagePostId,
          author: firebase.auth().currentUser.uid,
          authorFirstName,
          authorLastName,
          title,
          description,
          workingArea,
          tag,
          jobType,
          workingStart,
          qualification,
          location,
          uploadDate,
          updatedDate: uploadDate,
          views: 0,
        })
        .then(() => resolve(storagePostId))
        .catch((e) => reject(e));
    });

export const updateJobUpload = (docId, updateDic) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("jobs")
      .doc(docId)
      .update(updateDic)
      .then(() => resolve(docId))
      .catch((e) => reject(e));
  });

export const getAllJobs = () => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("jobs")
      .orderBy("uploadDate", "desc")
      .onSnapshot((snapshot) => {
        let jobs = snapshot.docs.map((doc) => {
          const data = doc.data();
          const docId = doc.id;
          return { docId, ...data };
        });
        dispatch({
          type: ALL_JOBS_CHANGED,
          allJobs: jobs,
        });
      });
  });

export const deleteJobsByDocId = (docId) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("jobs")
      .doc(docId)
      .delete()
      .then(() => resolve())
      .catch(() => reject());
  });

export const getJobByDocId = (docId) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("jobs")
      .doc(docId)
      .onSnapshot((snapshot) => {
        dispatch({
          type: CURRENT_JOB_CHANGED,
          currentJob: { docId, ...snapshot.data() },
        });
      });
  });
