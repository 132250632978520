/* eslint-disable no-sparse-arrays */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import {
  getDiscussionByDocId,
  increaseViewOnDiscussion,
} from "../store/actions/discussion";
import { MdPerson, MdHistory, MdDownload } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import IconLabelRow from "../components/general/IconLabelRow";
import Spinner from "../components/general/Spinner";
import FooterSection from "../sections/FooterSection";

export default function DiscussionDetailScreen() {
  const discussion = useSelector((state) => state.discussion.currentDiscussion);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDiscussionByDocId(id));
  }, [,]);

  useEffect(() => {
    if (discussion !== null && discussion !== undefined) {
      dispatch(increaseViewOnDiscussion(discussion.id));
    }
  }, [,]);

  return (
    <>
      <NavBar />
      {discussion !== null &&
      discussion !== undefined &&
      discussion.id === id ? (
        <div className="w-full flex items-center flex-col px-4 md:px-20 lg:px-40 pt-12">
          <img
            src={discussion.thumb}
            alt=""
            className="rounded w-full h-96 object-cover"
          />
          <div className="flex flex-row justify-center my-2">
            <IconLabelRow
              icon={<MdPerson className="" />}
              label={
                discussion.authorFirstName + " " + discussion.authorLastName
              }
            />
            <IconLabelRow
              icon={<MdHistory />}
              label={new Date(
                discussion.uploadDate.seconds * 1000
              ).toLocaleDateString()}
            />
            <IconLabelRow icon={<IoMdEye />} label={discussion.views} />
          </div>
          <Title text={discussion.title} />
          <p className="whitespace-pre-line">{discussion.content}</p>
          {discussion.files !== undefined && discussion.files.length !== 0 && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 py-10">
              {discussion.files.map((file) => (
                <a
                  className="bg-yellow-400 text-white rounded px-4 py-2 hover:text-yellow-400 hover:bg-white hover:border hover:border-solid hover:border-yellow-400 transition-colors flex flex-row justify-between align-middle items-center"
                  href={file.src}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.name}
                  <div className="w-1/6 flex justify-end">
                    <MdDownload size={24} />
                  </div>
                </a>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-screen flex flex-col items-center">
          <Spinner />
          <p>Diskussion wird geladen...</p>
        </div>
      )}
      <FooterSection />
    </>
  );
}
