import React from "react";
import { MdClose } from "react-icons/md";

const SingleFile = ({ file, removeFilesById, fromStorage = false }) => (
  <div className="rounded bg-yellow-400 px-4 p-2 flex flex-row my-2 justify-between w-96">
    <p className="text-white break-words w-80">
      {fromStorage ? file.name : file.src.path}
    </p>

    <button
      onClick={() =>
        removeFilesById(fromStorage ? file.src : file.id, fromStorage)
      }
      className="w-10"
    >
      <MdClose
        size={24}
        className="text-white hover:text-blue-400 transition-colors"
      />
    </button>
  </div>
);

export default function FileGrid({
  files,
  removeFilesById,
  fromStorage = false,
}) {
  const renderFile = (file, index) => {
    return (
      <SingleFile
        file={file}
        key={`${fromStorage ? file.name : file.id}-file`}
        removeFilesById={removeFilesById}
        fromStorage={fromStorage}
      />
    );
  };
  return <div>{files.map(renderFile)}</div>;
}
