import React, { useState } from "react";
import NavBarItem from "../components/general/NavBarItem";

export default function NavBar({ visibleSection }) {
  const [navbar, setNavbar] = useState(false);
  return (
    <nav className="w-full bg-blue-300 shadow fixed top-0 z-20">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl lg:items-center lg:flex lg:px-8">
        <div>
          <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
            <a href="/#start" className="flex flex-row">
              <img
                src="./assets/images/logo.png"
                alt=""
                className="w-16 pr-4"
              />
              <h2 className="text-2xl font-bold text-white">Most e.V.</h2>
            </a>
            <div className="lg:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 lg:block lg:pb-0 lg:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 lg:flex lg:space-x-6 lg:space-y-0">
              <NavBarItem label="Start" link="/#start" setNavbar={setNavbar} />
              <NavBarItem
                label="Jugendämter"
                link="/jugendaemter"
                setNavbar={setNavbar}
              />
              <NavBarItem
                label="Stellenausschreibungen"
                link="/jobs"
                setNavbar={setNavbar}
              />
              <NavBarItem
                label="Fachdiskussion"
                link="/diskussion"
                setNavbar={setNavbar}
              />
              <NavBarItem
                label="Україна"
                link="/ukraine"
                setNavbar={setNavbar}
              />
              <NavBarItem
                label="Träger"
                link="/traeger"
                setNavbar={setNavbar}
              />
              <NavBarItem
                label="Impressum"
                link="/impressum"
                setNavbar={setNavbar}
              />
            </ul>

            <div className="mt-3 space-y-2 lg:hidden">
              <a
                href="/kontakt"
                className="inline-block w-full px-4 py-2 text-center text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
              >
                Kontakt
              </a>
            </div>
          </div>
        </div>
        <div className="hidden space-x-2 lg:inline-block">
          <a
            href="/kontakt"
            className="inline-block w-full px-4 py-2 text-center text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
          >
            Kontakt
          </a>
        </div>
      </div>
    </nav>
  );
}
