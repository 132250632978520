import React from "react";

export default function CustomJobItemType({ jobType }) {
  return (
    <span
      key={jobType}
      className="bg-blue-100 text-blue-700 rounded-full px-3 py-1 text-sm mr-4 flex items-center text-center"
    >
      {jobType}
    </span>
  );
}
