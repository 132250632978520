import React from "react";
import { MdDelete, MdEdit, MdLocationPin } from "react-icons/md";

export default function JobCard({
  type,
  jobTitle,
  location,
  time,
  workingArea,
  qualification,
  workingStart,
  id,
  admin = false,
  onDeleteClick,
  onUpdateClick,
}) {
  return !admin ? (
    <a
      href={"/job/" + id}
      className="bg-white w-full shadow-xl shadow-gray-100 max-w-4xl flex flex-col sm:flex-row gap-3 sm:items-center justify-between px-5 py-4 rounded-md my-5 mx-5 lg:mx-0"
    >
      <div>
        <div className="flex justify-between">
          <span className="w-full flex text-blue-600 text-sm text-start">
            {type}
          </span>
        </div>
        <h3 className="font-bold mt-px flex">{jobTitle}</h3>
        <span className="w-full flex flex-col lg:flex-row">
          <p className="font-semibold break-words">Arbeitsbereich:</p>
          <p className="text-start lg:pl-5">{workingArea}</p>
        </span>
        <span className="w-full flex flex-col lg:flex-row">
          <p className="font-semibold break-words">Berufsabschluss:</p>
          <p className="text-start lg:pl-5">{qualification}</p>
        </span>
        <div className="flex items-center gap-3 mt-2 flex-col md:flex-row lg:flex-row">
          <div className="flex">
            {time.map(function (name, index) {
              return (
                <span
                  key={index}
                  className="bg-blue-100 text-blue-700 rounded-full px-3 mx-2 py-1 text-sm text-center flex items-center"
                >
                  {name}
                </span>
              );
            })}
          </div>
          <span className="bg-yellow-100 text-yellow-700 rounded-full px-3 py-1 text-sm">
            {workingStart}
          </span>
          <span className="text-slate-600 text-sm flex gap-1 items-center">
            <MdLocationPin size={18} className="w-12" />
            <p>{location}</p>
          </span>
        </div>
      </div>
      <div>
        <div
          href={"job/" + id}
          className="bg-yellow-100 text-yellow-700 hover:text-yellow-100 hover:bg-yellow-700 transition-colors font-medium px-4 py-2 rounded-md flex gap-1 items-center"
        >
          Mehr erfahren
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </div>
      </div>
    </a>
  ) : (
    <div className="bg-white w-full shadow-xl shadow-gray-100 max-w-4xl flex flex-col sm:flex-row gap-3 sm:items-center justify-between px-5 py-4 rounded-md my-5 mx-5 lg:mx-0">
      <div>
        <div className="flex justify-between">
          <span className="w-full flex text-blue-600 text-sm text-start">
            {type}
          </span>
          {admin && (
            <button
              onClick={onUpdateClick}
              className="text-yellow-400 hover:text-yellow-700 transition-color"
            >
              <MdEdit size={28} />
            </button>
          )}
          {admin && (
            <button
              onClick={onDeleteClick}
              className="text-blue-400 hover:text-blue-700 transition-color"
            >
              <MdDelete size={28} />
            </button>
          )}
        </div>
        <h3 className="font-bold mt-px flex">{jobTitle}</h3>
        <span className="w-full flex flex-col lg:flex-row">
          <p className="font-semibold break-words">Arbeitsbereich:</p>
          <p className="text-start lg:pl-5">{workingArea}</p>
        </span>
        <span className="w-full flex flex-col lg:flex-row">
          <p className="font-semibold break-words">Berufsabschluss:</p>
          <p className="text-start lg:pl-5">{qualification}</p>
        </span>
        <div className="flex items-center gap-3 mt-2 flex-col md:flex-row lg:flex-row">
          <div className="flex">
            {time.map(function (name, index) {
              return (
                <span
                  key={index}
                  className="bg-blue-100 text-blue-700 rounded-full px-3 mx-2 py-1 text-sm text-center flex items-center"
                >
                  {name}
                </span>
              );
            })}
          </div>
          <span className="bg-yellow-100 text-yellow-700 rounded-full px-3 py-1 text-sm">
            {workingStart}
          </span>
          <span className="text-slate-600 text-sm flex gap-1 items-center">
            <MdLocationPin size={18} className="w-12" />
            <p>{location}</p>
          </span>
        </div>
      </div>
      <div>
        <a
          href={"job/" + id}
          className="bg-yellow-100 text-yellow-700 hover:text-yellow-100 hover:bg-yellow-700 transition-colors font-medium px-4 py-2 rounded-md flex gap-1 items-center"
        >
          Mehr erfahren
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
