import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoCardRow from "../../components/general/InfoCardRow";
import Title from "../../components/general/Title";
import { MdHome } from "react-icons/md";
import { deleteOfferByDocId, getAllOffers } from "../../store/actions/offers";

export default function ManageOfferSection({ setOpenSection, setUpdateProperties }) {
  const offers = useSelector((state) => state.offers.allOffers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOffers());
  }, [dispatch]);

  const deleteOffer = (docId) => {
    dispatch(deleteOfferByDocId(docId)).then(() => dispatch(getAllOffers()));
  };

  const updateOffer = (offer) => {
    setOpenSection("updateOffer");
    setUpdateProperties(offer);
  };

  return (
    <div className="px-4 lg:px-10">
      <div className="flex flex-row w-full justify-center px-4 lg:px-10">
        <Title text="Angebote verwalten" />
        <button
          className="text-slate-900 hover:text-slate-600 transition-colors"
          onClick={() => setOpenSection("home")}
        >
          <MdHome size={42} />
        </button>
      </div>
      {offers.length !== 0 ? (
        offers.map((offer) => (
          <InfoCardRow
            title={offer.title}
            author={offer.authorFirstName + " " + offer.authorLastName}
            category="Wissen"
            info={offer.content}
            admin={true}
            onDeleteClick={() => deleteOffer(offer.docId)}
            onUpdateClick={() => updateOffer(offer)}
          />
        ))
      ) : (
        <p className="text-slate-900 text-center mx-96">
          Es sind derzeit keine Angebote vorhanden!
          <button
            className="ml-3 hover:text-slate-600 transition-colors"
            href=""
            onClick={() => setOpenSection("addOffer")}
          >
            Klicke hier um eine zu erstellen.
          </button>
        </p>
      )}
    </div>
  );
}
