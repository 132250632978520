import { All_OFFERS_CHANGED, CURRENT_OFFER_CHANGED } from "../constants";

const initialState = {
  allOffers: [],
  currentOffer: null,
};

export const offers = (state = initialState, action) => {
  switch (action.type) {
    case All_OFFERS_CHANGED:
      return {
        ...state,
        allOffers: action.allOffers,
      };
    case CURRENT_OFFER_CHANGED:
      return {
        ...state,
        currentOffer: action.currentOffer,
      };
    default:
      return state;
  }
};
