import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoCardRow from "../../components/general/InfoCardRow";
import Title from "../../components/general/Title";
import {
  deleteUkraineByDocId,
  getAllUkraine,
} from "../../store/actions/ukraine";
import { MdHome } from "react-icons/md";

export default function ManageUkraineSection({
  setOpenSection,
  setUpdateProperties,
}) {
  const ukraine = useSelector((state) => state.ukraine.allUkraine);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUkraine());
  }, [dispatch]);

  const deleteUkraine = (docId) => {
    dispatch(deleteUkraineByDocId(docId)).then(() =>
      dispatch(getAllUkraine())
    );
  };

  const updateUkraine = (ukraine) => {
    setOpenSection("updateUkraine");
    setUpdateProperties(ukraine);
  };

  return (
    <div className="px-4 lg:px-10">
      <div className="flex flex-row w-full justify-center">
        <Title text="Ukraine Themen verwalten" />
        <button
          className="text-slate-900 hover:text-slate-600 transition-colors"
          onClick={() => setOpenSection("home")}
        >
          <MdHome size={42} />
        </button>
      </div>
      {ukraine.length !== 0 ? (
        ukraine.map((discussion) => (
          <InfoCardRow
            title={discussion.title}
            author={
              discussion.authorFirstName + " " + discussion.authorLastName
            }
            category="Wissen"
            info={discussion.content}
            admin={true}
            onDeleteClick={() => deleteUkraine(discussion.docId)}
            onUpdateClick={() => updateUkraine(discussion)}
            key={discussion.id}
          />
        ))
      ) : (
        <p className="text-slate-900 text-center mx-96">
          Es sind derzeit keine Ukraine Themen vorhanden!
          <button
            className="ml-3 hover:text-slate-600 transition-colors"
            href=""
            onClick={() => setOpenSection("addUkraine")}
          >
            Klicke hier um eines zu erstellen.
          </button>
        </p>
      )}
    </div>
  );
}
