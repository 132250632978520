export const discussionSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="562"
    height="486.80525"
    viewBox="0 0 562 486.80525"
    className="object-cover w-56 h-56"
  >
    <path
      d="M553.88171,596.17145a15.51335,15.51335,0,0,0,15.61066,15.38848l265.99316-1.90637a15.5,15.5,0,0,0-.22216-30.9992L569.2702,580.56073A15.5134,15.5134,0,0,0,553.88171,596.17145Z"
      transform="translate(-319 -208.75509)"
      fill="#f2f2f2"
    />
    <path
      d="M585.78864,592.46466a.9845.9845,0,0,1,.98-.98706l68.70306-.24567a.98365.98365,0,1,1,.007,1.96729h0l-68.70307.24566A.98477.98477,0,0,1,585.78864,592.46466Z"
      transform="translate(-319 -208.75509)"
      fill="#ccc"
    />
    <path
      d="M585.868,601.48187a.98451.98451,0,0,1,.98-.98706l176.88977-1.04944a.98366.98366,0,0,1,.01123,1.96729h-.00421L586.855,602.4621A.98474.98474,0,0,1,585.868,601.48187Z"
      transform="translate(-319 -208.75509)"
      fill="#ccc"
    />
    <path
      d="M553.88171,537.17145a15.51335,15.51335,0,0,0,15.61066,15.38848l265.99316-1.90637a15.5,15.5,0,0,0-.22216-30.9992L569.2702,521.56073A15.5134,15.5134,0,0,0,553.88171,537.17145Z"
      transform="translate(-319 -208.75509)"
      fill="#f2f2f2"
    />
    <path
      d="M582.868,542.48187a.98451.98451,0,0,1,.98-.98706l176.88977-1.04944a.98365.98365,0,1,1,.007,1.96729h0L583.855,543.4621A.98474.98474,0,0,1,582.868,542.48187Z"
      transform="translate(-319 -208.75509)"
      fill="#ccc"
    />
    <path
      d="M582.868,532.48187a.98451.98451,0,0,1,.98-.98706l176.88977-1.04944a.98365.98365,0,1,1,.007,1.96729h0L583.855,533.4621A.98474.98474,0,0,1,582.868,532.48187Z"
      transform="translate(-319 -208.75509)"
      fill="#ccc"
    />
    <path
      d="M631.7298,664.56073l-265.99317-1.90637a15.5,15.5,0,0,0-.22216,30.9992l265.99316,1.90637a15.5,15.5,0,0,0,.22217-30.9992Z"
      transform="translate(-319 -208.75509)"
      fill="#f2f2f2"
    />
    <path
      d="M449.2243,677.44488l-68.70309-.24566a.98365.98365,0,1,1,.007-1.96729l68.70309.24567a.98365.98365,0,1,1-.007,1.96728Z"
      transform="translate(-319 -208.75509)"
      fill="#ccc"
    />
    <path
      d="M558.1449,686.4621l-176.88977-1.04944a.98365.98365,0,1,1,.007-1.96729h0l176.88974,1.04944a.98365.98365,0,1,1-.007,1.96729Z"
      transform="translate(-319 -208.75509)"
      fill="#ccc"
    />
    <circle cx="555" cy="327.16309" r="7" fill="#f2f2f2" />
    <circle cx="555" cy="386.16309" r="7" fill="#f2f2f2" />
    <path
      d="M559.72754,639.23578,367.28165,668.06433a12.07064,12.07064,0,1,1-3.57648-23.87488L556.151,615.361a12.07064,12.07064,0,1,1,3.57648,23.87488Z"
      transform="translate(-319 -208.75509)"
      fill="#6c63ff"
    />
    <path
      d="M425.82855,646.121l-49.67862,7.62439a.7196.7196,0,0,1-.21832-1.42255h0l49.67862-7.62439a.7196.7196,0,0,1,.21832,1.42255Z"
      transform="translate(-319 -208.75509)"
      fill="#fff"
    />
    <path
      d="M505.56784,640.26849,377.613,659.59772a.7196.7196,0,0,1-.21833-1.42255h0L505.34952,638.846a.7196.7196,0,1,1,.21832,1.42254Z"
      transform="translate(-319 -208.75509)"
      fill="#fff"
    />
    <path
      d="M515.0224,624.72589a5.16179,5.16179,0,0,1-4.74048-6.27124l-20.917-14.98023,2.721-6.80438,24.71338,17.97388a5.1546,5.1546,0,0,1-1.77692,10.082Z"
      transform="translate(-319 -208.75509)"
      fill="#a0616a"
    />
    <path
      d="M458.8288,540.96918l-12.569,4.5996a4.47859,4.47859,0,0,0-2.66672,5.74494q.074.20234.16727.39685l17.137,35.74939L502.188,617.21948l6.32873-10.0838-31.27807-28.48163Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <path
      d="M453.66049,614.90118c-14.32382-1.33142-28.355-4.638-42.70077-5.75l5.75732-14.653,29.4231,4.38208Z"
      transform="translate(-319 -208.75509)"
      fill="#a0616a"
    />
    <path
      d="M405.06342,618.3468l-.12436,1.09735-1.0242,8.81519-1.19977.07312-.0658,11.03918a5.13859,5.13859,0,0,1-2.09955,6.01337,4.21657,4.21657,0,0,1-.50476.278,52.55557,52.55557,0,0,1,.6511-17.19879,30.66231,30.66231,0,0,1,2.04834-6.11584,25.03154,25.03154,0,0,1,1.53625-2.81647C404.52206,619.12958,404.78543,618.73449,405.06342,618.3468Z"
      transform="translate(-319 -208.75509)"
      fill="#2f2e41"
    />
    <path
      d="M405.29019,616.44476l-.22677,1.89472v.00732l-.12436,1.09735c-.32187.04389-.54865.07312-.65842.08777a.63456.63456,0,0,1-.08048.00732Z"
      transform="translate(-319 -208.75509)"
      fill="#6c63ff"
    />
    <path
      d="M457.3768,580.11584l-2.47266,14.38233s6.00608,13.53375,1.87278,16.90625a4.22757,4.22757,0,0,0-1.37534,3.65045c-.58523-.05121-1.16315-.10242-1.74109-.15363-14.32382-1.33142-28.355-4.638-42.70077-5.75-1.01688-.08044-2.02643-.15362-3.04325-.20483l4.41858-12.56806-2.98474-11.17077-3.09445-11.58783-.66568-19.07892a10.6774,10.6774,0,0,1,.00726-1.0827c0-.19019.00733-.37311.022-.5633a16.16627,16.16627,0,0,1,6.84732-12.15838.02577.02577,0,0,0,.01464-.00733,16.20023,16.20023,0,0,1,2.12146-1.25824,15.715,15.715,0,0,1,2.46534-.973l.84863-.256,4.59412-7.43262,5.32574-.556,1.97519-.20483,12.868-1.33875,2.77261,3.59925.50477.65838,1.95321,2.53119,6.08652,3.24079a14.91892,14.91892,0,0,1,3.60654,2.67749,15.14139,15.14139,0,0,1,4.36737,9.2688,14.91894,14.91894,0,0,1,.04388,2.42877Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <path
      d="M394.73956,515.34356c-.13214,5.68567,1.99268,11.191,5.0452,16.02656a30.40274,30.40274,0,0,1,4.23785-7.84137,24.40954,24.40954,0,0,0,2.59775,15.45855,3.27754,3.27754,0,0,0,.27808.17835,9.58077,9.58077,0,0,0,8.33413,1.02191,18.69122,18.69122,0,0,1-1.60647-22.65094c1.32-1.908,3.02725-3.59537,3.84485-5.76666,1.29092-3.46442.05-7.27881-.73282-10.89081-.732-3.3617-.86011-7.36212,1.3952-9.82581a2.37012,2.37012,0,0,0-2.17218-1.00012,4.48308,4.48308,0,0,0-3.04736,2.6499,19.33328,19.33328,0,0,1-1.9682,3.69965c-1.66373,2.01248-4.497,2.48828-6.88468,3.54889C398.2785,502.52725,394.87817,509.017,394.73956,515.34356Z"
      transform="translate(-319 -208.75509)"
      fill="#2f2e41"
    />
    <path
      d="M443.41318,512.09274c-.33045-1.15652,1.94711,5.91306.84808,6.04068-5.00558.57422-11.877,3.56372-15.36331,4.95837-.157-.01824-.314-.03649-.4664-.05926-.374-.041-.73886-.10028-1.099-.16864-8.11792-1.53595-11.23965-14.006-11.23965-14.12,0-.56057,1.34545-15.9577,3.465-16.18557a15.62024,15.62024,0,0,1,1.67621-.09116h5.25955a24.4417,24.4417,0,0,1,3.30164-.47855h.00924c10.58838-.80673,18.93717,5.647,18.15677,13.49994-.0046.0091-.01385.01822-.01846.02735-.22629.33725-.42483.65631-.60034.96167-.17084.28714-.314.56057-.43866.82037-.96512,2.0054,1.51565,4.52881-.1293,4.99982-2.18781.62643,5.75405,21.21615-2.90558,21.21615C448.08121,534.71161,444.32474,515.28314,443.41318,512.09274Z"
      transform="translate(-319 -208.75509)"
      fill="#2f2e41"
    />
    <circle cx="114.96106" cy="302.96619" r="12.45264" fill="#a0616a" />
    <path
      d="M447.9613,506.16772c-.20779.10938-.411.20963-.6188.30994-.217.1048-.434.20965-.65109.30536-4.1698,1.89148-7.56379,2.1011-9.62329-.61527a11.5123,11.5123,0,0,0-.94663-4.14752,6.66635,6.66635,0,0,1-1.099,4.14752h-4.14667c-4.27136,2.8349-6.46478,3.13117-3.50021,12.438.82657,2.59332,4.11316,17.6839,2.82022,19.92175-.374-.041-4.3533-16.56988-4.71347-16.63824-8.11792-1.53595-13.27588-14.91025-12.61554-15.66681a13.94366,13.94366,0,0,1,1.44534-6.203,14.26749,14.26749,0,0,1,5.42118-5.89768,25.142,25.142,0,0,1,4.33139-1.6271c.03695-.00913.06927-.01825.1062-.02735.45715-.12307.92355-.237,1.38992-.33273a25.56923,25.56923,0,0,1,2.91837-.44208c.06927-.00454.13852-.00912.20318-.00912a2.19919,2.19919,0,0,1,1.11286.30539c.00461,0,.00461.00455.00924.00455a2.09465,2.09465,0,0,1,.61878.55148,2.1193,2.1193,0,0,1,.45254,1.3172h4.61768q.25626,0,.51257.01364A12.38136,12.38136,0,0,1,447.94284,505.516C447.95667,505.73474,447.9613,505.949,447.9613,506.16772Z"
      transform="translate(-319 -208.75509)"
      fill="#2f2e41"
    />
    <circle cx="101.73425" cy="285.22797" r="8.20388" fill="#2f2e41" />
    <path
      d="M400.54974,645.385a4.21657,4.21657,0,0,1-.50476.278,5.15136,5.15136,0,1,1-4.55368-9.24176q.13161-.06482.26679-.12213l-.01465-7.52765-.01465-8.85913-.01459-9.342,7.11066-1.75574-.08048,13.53376-.02929,5.98407-.0658,11.03918A5.13859,5.13859,0,0,1,400.54974,645.385Z"
      transform="translate(-319 -208.75509)"
      fill="#a0616a"
    />
    <path
      d="M428.656,549.30279l-.7389,1.38263-18.56683,34.522-.556,1.03149-2.62625,22.627-.87787,7.57886-.22677,1.89471v.00733l-.12436,1.09735-1.0242,8.81519-1.19977.07312-2.019.13171-4.95263.30725-3.709.23407.00733-5.93292.06579-44.96125,13.48984-24.65338.32922-.60717,5.20865-9.51752a4.48746,4.48746,0,0,1,6.09192-1.77838q.18526.10163.36038.22021Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <circle cx="7" cy="466.16309" r="7" fill="#f2f2f2" />
    <path
      d="M805.9281,421.20633s-2.53845,26.86993-3.943,46.76086v.01465q-.1647,2.40312-.30725,4.64536l-.74616.06585c-.51208.05121-1.01684.10971-1.529.16092-8.17877.87055-16.277,2.31174-24.4046,3.56265q-1.17417.17565-2.34833.35117a152.19083,152.19083,0,0,1-24.52167,1.98981h-.15362c.06585-1.02414.16821-2.36292.28534-3.8992.68768-8.69812,2.07757-23.77549,2.07757-23.77549l1.29486-8.72741,4.23566-28.589.0293-.21952.03656-.24868.20483-.05121,8.89569-2.31168,4.78436-1.23633,2.77991-3.83337,3.70166-5.10623,15.39191,1.89474,1.12659,4.1991,2.085,7.73984.06579.24872,8.07636,4.68927Z"
      transform="translate(-319 -208.75509)"
      fill="#e6e6e6"
    />
    <circle cx="464.95712" cy="174.03893" r="16.31188" fill="#ffb6b6" />
    <path
      d="M766.86505,372.92209c1.43219,1.15744,1.10443,6.78647,2.70038,5.869s1.928-2.56054,3.76575-2.57778a6.01723,6.01723,0,0,1,3.96258,1.79318c.19562.17679.38166.36215.56238.54538.16528-.058.32849-.11051.50024-.15354a5.18287,5.18287,0,0,1,3.80127.53525,5.0854,5.0854,0,0,0-2.89532,1.16745c.35077.39313.70685.78845,1.081,1.15381,3.124,3.06146,9.52948-.19879,13.83643-.93445-3.58722,3.91675-2.88,6.10324-1.98779,11.33841l.00372.286c.18347-1.2073,4.05011-1.14712,3.41266.45484a20.59327,20.59327,0,0,1-2.98023,4.381c5.26856-3.64987,9.52179-8.68094,10.46161-14.75824a8.93419,8.93419,0,0,0,.20538-3.82739,2.93513,2.93513,0,0,0-2.80334-2.235,10.815,10.815,0,0,0-13.3009-13.64243,41.33293,41.33293,0,0,0-5.00336,2.15955,8.99354,8.99354,0,0,1-5.29919.89755c-1.13166-.23376-2.27784-.81973-3.38715-.48794-1.28528.38547-1.83777,1.74289-2.353,3.05331a5.177,5.177,0,0,1,2.412,1.81943,5.06559,5.06559,0,0,0-3.11646-.12662.31645.31645,0,0,0-.05542.015C769.22461,371.73318,769.15729,372.24454,766.86505,372.92209Z"
      transform="translate(-319 -208.75509)"
      fill="#2f2e41"
    />
    <path
      d="M774.99805,476.41662q-1.17408.17565-2.34833.35117a152.19092,152.19092,0,0,1-24.52167,1.98981c.05847-1.29486.09509-2.597.13165-3.8992.05121-1.82157.08777-3.65046.12433-5.47934q.25233-12.43269.50476-24.85816c.00733-.395.01465-.79742.02192-1.19244.16827-8.37628.39508-17.03052,3.86993-24.66068a25.15318,25.15318,0,0,1,2.94812-4.8941c.05853-.08048.10974-.1536.16827-.22678.08045-.10238.161-.2048.2414-.29989a24.29793,24.29793,0,0,1,3.1018-3.21152,17.87074,17.87074,0,0,1,13.007-4.70389l.35114.534.4682.72427c-.30725,2.28974-.62183,4.58682-.91443,6.88391-1.26556,9.95642-2.17267,19.97141-1.15588,29.93515.86328,8.37632,3.07983,16.577,3.78216,24.96061.02924.278.05121.56332.07312.8413A69.71563,69.71563,0,0,1,774.99805,476.41662Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <path
      d="M800.93,472.68817c.36-1.56.7-3.13,1.06-4.71v-.01q2.08512-9.36,4.18-18.72c1.83-8.18,3.66-16.64,2.09-24.87a24.77831,24.77831,0,0,0-.61-2.51c-.08-.27-.17-.54-.26-.81a24.93325,24.93325,0,0,0-2.55-5.4c-2.78-4.44-6.94-7.89-12.02-8.8a1.6657,1.6657,0,0,0-.29-.06l-1.11,1.03c-.23,2.13-.45,4.28-.69,6.42-1.14,10.13-2.63,20.25-6.04,29.83-2.92,8.2-7.2,15.87-9.87,24.15-.01.05-.03.1-.04.14a70.77963,70.77963,0,0,0-2.13,8.4c.78-.12,1.57-.23,2.35-.35,8.13-1.25,16.22-2.69,24.4-3.56Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <path
      d="M816.81964,379.36184l-.46814-4.49176-.10974-1.03884-.16827-1.66791-3.6139-34.66834a4.7198,4.7198,0,0,0-2.07031-5.38421,4.52655,4.52655,0,0,0-6.20355,1.56552c-.022.03656-.03662.07315-.05853.10971a4.74625,4.74625,0,0,0,1.63867,6.40842,4.8073,4.8073,0,0,0,.68036.32189l-.02924.973-.08777,3.05056-.06585,2.30442-.139,4.58682-.05121,1.77771-.16827,5.76459-.16095,5.55984-.19751,6.73758-.15363,5.17941,1.86548.47553Z"
      transform="translate(-319 -208.75509)"
      fill="#ffb6b6"
    />
    <path
      d="M820.43353,386.74319a8.15334,8.15334,0,0,0-.43158-1.763c-.94372-1.6387-1.52166.651-1.00219-3.73828.417-3.56265-2.085-3.11639-1.18512-3.87723,1.485-1.25824-.5633-1.81424-1.46308-2.49456-.4024-.3-.57062-.62186-.10974-1.03885a3.104,3.104,0,0,1,.71692-.46817c.98029-.51209.417-.89985-.88513-1.19974a61.79056,61.79056,0,0,0-8.85913-.82666c-.71692-.03656-1.30213-.05121-1.66791-.06586-.27069-.00729-.42432-.00729-.42432-.00729a2.93279,2.93279,0,0,0-.89978,1.83615,10.18908,10.18908,0,0,0,.33649,4.44052,15.70113,15.70113,0,0,0,1.19244,2.88232A2.07159,2.07159,0,0,1,806,381.54919v.00733a1.61363,1.61363,0,0,1-.84131,1.08267c-.98761.65838-.4389,2.041.32923,3.3432a14.78822,14.78822,0,0,1,1.65332,2.80914c0,.89984-6.08655,20.02262-6.08655,20.02262l2.23852,28.87442a8.03127,8.03127,0,0,0,5.83777,1.6167,8.6254,8.6254,0,0,0,4.6015-2.341,20.36686,20.36686,0,0,0,4.25031-6.02072C823.8938,419.07052,821.69183,393.75149,820.43353,386.74319Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <path
      d="M709.439,339.12481a4.7406,4.7406,0,0,1,2.2387,6.22156,4.55092,4.55092,0,0,1-.3767.65064l21.04864,29.13913-7.82538,8.82653L704.6261,346.87631a4.72736,4.72736,0,0,1-1.31226-5.62033,4.52413,4.52413,0,0,1,6.01331-2.1853Q709.38346,339.09695,709.439,339.12481Z"
      transform="translate(-319 -208.75509)"
      fill="#ffb6b6"
    />
    <path
      d="M768.44232,424.56378s-2.81372,10.06424-15.94574,2.66992-27.3349-35.00665-27.4765-36.89862,1.62238-.31955-1.26819-3.65738c-2.35089-2.71466-.02917-3.751-1.19946-3.87662-3.086-.33108,2.27191-4.243-1.53858-3.79415s8.61451-8.38276,8.61451-8.38276,2.26446.05436,3.99267,4.88105c.97064,2.711.05371,4.56585,2.36707,4.55532s1.323,5.482,1.81219,6.20777,16.27692,13.14618,16.27692,13.14618Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <circle cx="431.97128" cy="76.08154" r="76.08154" fill="#6c63ff" />
    <path
      d="M712.31219,267.90576a20.918,20.918,0,0,1,1.79975,4.75766c1.03186,4.34677.30749,8.29159-2.06531,10.14181a4.59816,4.59816,0,0,1-.76465.50147c-2.96863,1.55246-7.06427-.08606-10.347-3.75226a20.9179,20.9179,0,0,1-2.88086-4.19229,20.44948,20.44948,0,0,1-1.7854-4.70877c-1.15644-4.80075-.17066-9.13055,2.80676-10.68762C703.01886,257.90356,708.94312,261.4635,712.31219,267.90576Z"
      transform="translate(-319 -208.75509)"
      fill="#fff"
    />
    <path
      d="M711.05188,275.33578a10.94482,10.94482,0,0,1,.99475,7.46945,4.59816,4.59816,0,0,1-.76465.50147c-2.96863,1.55246-7.06427-.08606-10.347-3.75226a20.9179,20.9179,0,0,1-2.88086-4.19229,20.44948,20.44948,0,0,1-1.7854-4.70877A10.9918,10.9918,0,0,1,711.05188,275.33578Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <path
      d="M741.84723,252.46c3.23468,6.18524,2.93878,12.78366-.57446,15.12881a4.59191,4.59191,0,0,1-.45575.27216c-3.93445,2.05759-9.85877-1.50235-13.22779-7.94455a20.36766,20.36766,0,0,1-1.78076-4.69989c-1.16113-4.80958-.17535-9.1394,2.80213-10.6965C732.55383,242.45785,738.47815,246.01777,741.84723,252.46Z"
      transform="translate(-319 -208.75509)"
      fill="#fff"
    />
    <path
      d="M740.29449,260.043a10.94756,10.94756,0,0,1,.97828,7.54583,4.59191,4.59191,0,0,1-.45575.27216c-3.93445,2.05759-9.85877-1.50235-13.22779-7.94455a20.36766,20.36766,0,0,1-1.78076-4.69989A10.9913,10.9913,0,0,1,740.29449,260.043Z"
      transform="translate(-319 -208.75509)"
      fill="#3f3d56"
    />
    <path
      d="M708.58124,311.61105c8.95788,17.12906,26.33648,25.72418,38.81622,19.19772s15.33478-25.703,6.37689-42.832C743.934,302.324,729.031,310.402,708.58124,311.61105Z"
      transform="translate(-319 -208.75509)"
      fill="#fff"
    />
    <path
      d="M818.48193,249.69211a76.10875,76.10875,0,0,0-77.53668-40.1648,76.08839,76.08839,0,0,1,.23541,150.84473,76.0624,76.0624,0,0,0,77.30127-110.67993Z"
      transform="translate(-319 -208.75509)"
      opacity="0.2"
    />
  </svg>
);

export const manageDiscussionSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="787.82738"
    height="453.11195"
    viewBox="0 0 787.82738 453.11195"
    className="object-cover w-56 h-56"
  >
    <circle cx="406.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
    <circle cx="406.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
    <circle cx="433.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
    <circle cx="433.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
    <circle cx="460.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
    <circle cx="460.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
    <circle cx="460.69135" cy="58.01349" r="6.46689" fill="#f2f2f2" />
    <circle cx="460.69135" cy="83.7868" r="6.46689" fill="#f2f2f2" />
    <circle cx="487.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
    <circle cx="487.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
    <circle cx="487.69135" cy="58.01349" r="6.46689" fill="#f2f2f2" />
    <circle cx="487.69135" cy="83.7868" r="6.46689" fill="#f2f2f2" />
    <circle cx="514.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
    <circle cx="514.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
    <circle cx="541.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
    <circle cx="541.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
    <path
      d="M945.20043,564.70241H897.19067c0,1.38-49.48,2.5-49.48,2.5a12.85957,12.85957,0,0,0-2.10987,2,12.41031,12.41031,0,0,0-2.90039,8v2a12.50461,12.50461,0,0,0,12.5,12.5h90a12.51087,12.51087,0,0,0,12.5-12.5v-2A12.51734,12.51734,0,0,0,945.20043,564.70241Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <rect x="688.10933" y="367.76179" width="13" height="84" fill="#3f3d56" />
    <path
      d="M944.19564,674.74913c0,1.40462-19.69947.5433-44,.5433s-44,.86132-44-.5433,19.69947-12.54331,44-12.54331S944.19564,673.3445,944.19564,674.74913Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <polygon
      points="629.1 440.257 616.84 440.256 611.007 392.968 629.102 392.969 629.1 440.257"
      fill="#ffb8b8"
    />
    <path
      d="M608.08269,436.75319h23.64384a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H593.19581a0,0,0,0,1,0,0v0A14.88688,14.88688,0,0,1,608.08269,436.75319Z"
      fill="#2f2e41"
    />
    <polygon
      points="609.962 433.414 598.265 429.743 606.857 382.878 624.121 388.296 609.962 433.414"
      fill="#ffb8b8"
    />
    <path
      d="M794.615,648.19284h23.64385a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H779.72813a0,0,0,0,1,0,0v0A14.88687,14.88687,0,0,1,794.615,648.19284Z"
      transform="translate(26.87877 -432.59753) rotate(17.42262)"
      fill="#2f2e41"
    />
    <path
      d="M912.36679,556.50519a37.87092,37.87092,0,0,0-1.52-7.02l-2.29-2.88-5.96-7.48-.25976.01c-.44043.02-2.27051.12-5.1001.34h-.03027c-1.75.14-3.89014.32-6.31983.55-.64014.07-1.31006.13-2,.2v10.44h12.5a2.5044,2.5044,0,0,1,2.48,2.18c.00977.11.02.21.02.32a2.501,2.501,0,0,1-2.5,2.5h-72.6001a28.4056,28.4056,0,0,0-2.66015,2,14.88157,14.88157,0,0,0-5.25,7.86c-4.23975,18.64-11.96973,53.4-14.25,63.61005a4.53816,4.53816,0,0,0,1.35009,4.28,4.43615,4.43615,0,0,0,1.47022.91l9.02,3.38a4.79293,4.79293,0,0,0,1.1499.27c.04981,0,.08985.01.14014.01a2.6135,2.6135,0,0,0,.27978.01,4.53212,4.53212,0,0,0,4.21-2.9l7.12012-18.68,10.97022-28.8,4.33984-11.39c5.02979,1.18,47.23,10.52,61.35986-4.53a16.06759,16.06759,0,0,0,3.24024-5.11005A20.80163,20.80163,0,0,0,912.36679,556.50519Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#2f2e41"
    />
    <path
      d="M924.60654,559.22516c-.21973-.89-.46973-1.81-.75977-2.74l-8.25-10.36-.25976.01c-.52051.03-2.99024.16-6.78028.47-4.2998.36-10.31006.94-17.06006,1.85-.1997.02-.40966.05-.60986.08-.66016.09-1.33008.18-2,.28v1.85h12.5a2.5044,2.5044,0,0,1,2.48,2.18c.00977.11.02.21.02.32a2.501,2.501,0,0,1-2.5,2.5H857.20664c-1.92969.63-3.79,1.29-5.56983,2-9.33984,3.7-16.33008,8.55-17.76025,14.86-.48,2.12-1.00977,4.45-1.56983,6.93-2.08008,9.22-4.60986,20.51-6.8999,30.77v.01c-.47021,2.12-.93994,4.18-1.39014,6.18-1.91015,8.6-3.52978,15.89-4.39013,19.72-.02.11-.03955.21-.06006.32v.02a4.66711,4.66711,0,0,0,.28027,2.29l.00977.00994v.01a4.42979,4.42979,0,0,0,1.30029,1.79,4.59324,4.59324,0,0,0,1.29.75l9.02,3.38a4.5805,4.5805,0,0,0,1.56982.29,4.435,4.435,0,0,0,2.14991-.55,4.501,4.501,0,0,0,2.06005-2.35l19.41993-50.96,3.01025-7.91c5.02979,1.18,47.23,10.51,61.35986-4.53a16.89106,16.89106,0,0,0,4.48-11.07A29.6861,29.6861,0,0,0,924.60654,559.22516Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#2f2e41"
    />
    <path
      d="M923.49667,471.00519c-.15966-.19-.33007-.38-.50976-.56a24.47215,24.47215,0,0,0-20.97022-7.39,79.36185,79.36185,0,0,0-9.91015,1.95,24.981,24.981,0,0,0-11.17969,6.26995,24.41423,24.41423,0,0,0-7.41016,18.2c.04,1.37.04981,2.77.03028,4.19,0,.66-.01026,1.33-.02,2h9.85986a5.32005,5.32005,0,0,1,1.27.15,5.511,5.511,0,0,1,4.23,5.35v46.52c.66992.19,1.33984.38,2,.58.21.06994.41016.12994.60986.18994.23.07.46.14.68018.21q3.04469.945,5.94971,2h3.26025a2.5044,2.5044,0,0,1,2.48,2.18c.68018.27,1.33985.54,1.98975.81,2.31006.96,4.5,1.92,6.51025,2.85,2.71,1.24,5.12012,2.42,7.14014,3.45a4.58719,4.58719,0,0,0,2.0498.5,4.404,4.404,0,0,0,2.6001-.86,5.531,5.531,0,0,0,.44971-.37,4.5045,4.5045,0,0,0,1.37011-3.98,128.08706,128.08706,0,0,1-.8999-23.7c.27979-6.65.87989-13.47,1.66016-20.06.41992-3.57.88965-7.07,1.37988-10.44.47022-3.22.97022-6.31,1.46-9.23A24.607,24.607,0,0,0,923.49667,471.00519Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#6c63ff"
    />
    <path
      d="M882.50693,471.82519a11.8589,11.8589,0,0,0-1.58008-.55,11.49858,11.49858,0,0,0-13.54,6.53l-6.87012,15.86-.85986,2h23.73a5.32005,5.32005,0,0,1,1.27.15l.79981-1.85,3.04-7.02A11.51151,11.51151,0,0,0,882.50693,471.82519Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#6c63ff"
    />
    <circle cx="697.75644" cy="207.24158" r="24.56103" fill="#ffb8b8" />
    <path
      d="M928.11679,501.04516l-14.02.38-1.02,9.85-.23,2.28-21.96,9.86-2,.9v17.53l2-.57,6.31983-1.8h.03027l27.83984-7.93.31983-.09a10.879,10.879,0,0,0,7.86035-10.35l.06982-11.63.06006-8.57Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ffb8b8"
    />
    <path
      d="M932.67685,481.56518a11.50161,11.50161,0,0,0-22.96,1.41l1.52978,24.96a4.48639,4.48639,0,0,0,1.83008,3.34,4.4354,4.4354,0,0,0,2.93994.87l10.72022-.66,3.25-.2a4.385,4.385,0,0,0,3.1001-1.51,3.13661,3.13661,0,0,0,.23974-.3,4.46893,4.46893,0,0,0,.87989-2.96Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#6c63ff"
    />
    <path
      d="M892.17685,548.66516h-1.29v-.13c-.66016.09-1.33008.18-2,.28v1.85h9.23975Q895.21665,549.61515,892.17685,548.66516Zm0,0h-1.29v-.13c-.66016.09-1.33008.18-2,.28v1.85h9.23975Q895.21665,549.61515,892.17685,548.66516Zm9.21,0h-10.5v-47.5a7.51079,7.51079,0,0,0-5.43017-7.2,7.05091,7.05091,0,0,0-2.06983-.3h-89a7.50906,7.50906,0,0,0-7.5,7.5v49a7.50264,7.50264,0,0,0,7.5,7.5h107a4.50458,4.50458,0,0,0,4.46973-4.01,3.90311,3.90311,0,0,0,.03027-.49A4.50681,4.50681,0,0,0,901.38681,548.66516Zm2.5,4.5a2.501,2.501,0,0,1-2.5,2.5h-107a5.50324,5.50324,0,0,1-5.5-5.5v-49a5.50328,5.50328,0,0,1,5.5-5.5h89a5.32005,5.32005,0,0,1,1.27.15,5.511,5.511,0,0,1,4.23,5.35v49.5h12.5a2.5044,2.5044,0,0,1,2.48,2.18C903.87656,552.95514,903.88681,553.05517,903.88681,553.16516Zm-11.71-4.5h-1.29v-.13c-.66016.09-1.33008.18-2,.28v1.85h9.23975Q895.21665,549.61515,892.17685,548.66516Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <circle cx="632.79741" cy="302.22257" r="6" fill="#6c63ff" />
    <path
      d="M898.59849,420.94279c-5.70477,5.09634-13.35364,10.56649-20.33919,6.83271-4.58683-2.45166-6.62894-7.82807-5.55768-12.91748,1.92644-9.15224,9.83844-14.09737,17.49912-18.09485,9.95887-5.19672,20.9487-9.72862,32.10037-8.37761s22.04719,10.56576,21.52059,21.78662c-.42347,9.02359-7.792,17.44162-5.45946,26.1688,2.34752,8.78321,13.00847,12.18439,22.089,12.6312s19.07664-.32741,26.28,5.2194c9.18922,7.076,9.04206,21.87216,2.50039,31.4491s-17.58849,14.9659-28.43879,19.06252c-14.376,5.42776-30.17738,9.34947-45.01669,5.359s-27.83036-18.22717-25.65383-33.43873c.91936-6.42534,4.29979-12.19318,7.562-17.80464s6.54354-11.46254,7.22857-17.91707c.57085-5.37878-1.13769-11.31634-5.10477-14.78115a4.437,4.437,0,0,1-1.25635-5.067Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#2f2e41"
    />
    <path
      d="M207.277,676.556H980.571a1.19068,1.19068,0,1,0,0-2.38137H207.277a1.19069,1.19069,0,1,0,0,2.38137Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <path
      d="M246.51632,577.20241v2a12.51087,12.51087,0,0,0,12.5,12.5h90a12.50461,12.50461,0,0,0,12.5-12.5v-2a12.41031,12.41031,0,0,0-2.90039-8,12.85957,12.85957,0,0,0-2.10987-2s-49.48-1.12-49.48-2.5H259.01632A12.51734,12.51734,0,0,0,246.51632,577.20241Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <rect
      x="297.02111"
      y="591.20582"
      width="13"
      height="84"
      transform="translate(400.9559 1042.96761) rotate(-180)"
      fill="#3f3d56"
    />
    <path
      d="M260.02111,674.74913c0,1.40462,19.69947.5433,44,.5433s44,.86132,44-.5433-19.69947-12.54331-44-12.54331S260.02111,673.3445,260.02111,674.74913Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <polygon
      points="163.53 441.018 175.79 441.018 181.622 393.73 163.528 393.731 163.53 441.018"
      fill="#a0616a"
    />
    <path
      d="M366.98961,660.9589h38.53072a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H381.87649a14.88688,14.88688,0,0,1-14.88688-14.88688v0A0,0,0,0,1,366.98961,660.9589Z"
      transform="translate(566.45414 1113.34302) rotate(179.99738)"
      fill="#2f2e41"
    />
    <polygon
      points="182.668 434.176 194.365 430.505 185.773 383.64 168.509 389.058 182.668 434.176"
      fill="#a0616a"
    />
    <path
      d="M386.5436,648.95453h38.53072a0,0,0,0,1,0,0V663.8414a0,0,0,0,1,0,0H401.43046a14.88687,14.88687,0,0,1-14.88687-14.88687v0a0,0,0,0,1,0,0Z"
      transform="translate(783.45104 937.73147) rotate(162.57738)"
      fill="#2f2e41"
    />
    <path
      d="M380.55229,635.8551,358.125,576.984c-5.03149,1.18164-47.22656,10.51856-61.35888-4.52832-4.84327-5.15723-5.78785-12.6289-2.80762-22.208l8.24756-10.36425.25634.01367c3.11841.16406,76.43531,4.27344,81.46119,26.38867,4.23755,18.64453,11.97656,53.40527,14.24731,63.61523a4.51574,4.51574,0,0,1-2.814,5.1836l-9.01929,3.38183a4.462,4.462,0,0,1-1.572.28614A4.513,4.513,0,0,1,380.55229,635.8551Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#2f2e41"
    />
    <path
      d="M367.55229,642.8551,345.125,583.984c-5.03174,1.18164-47.22656,10.51758-61.35888-4.52832-4.84327-5.15723-5.78785-12.6289-2.80762-22.208l8.24756-10.36425.25634.01367c3.11841.16406,76.43531,4.27344,81.46119,26.38867,4.23755,18.64453,11.97656,53.40527,14.24731,63.61523a4.51574,4.51574,0,0,1-2.814,5.1836l-9.01929,3.38183a4.462,4.462,0,0,1-1.572.28614A4.513,4.513,0,0,1,367.55229,642.8551Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#2f2e41"
    />
    <path
      d="M347.37876,528.63727a19.1964,19.1964,0,0,1-16.4845-9.37158l-7.12255-12.06592,16.92932-9.25293,7.18835,9.856,48.77356-5.13867a10.55928,10.55928,0,0,1,.79919-1.23389h0a11.01566,11.01566,0,0,1,7.8733-4.314,10.89328,10.89328,0,0,1,8.49267,3.0122A11.02275,11.02275,0,0,1,415.688,513.692a11.00369,11.00369,0,0,1-16.1134,3.15088,10.40153,10.40153,0,0,1-.82287-.7002l-46.7146,11.91065A18.83681,18.83681,0,0,1,347.37876,528.63727Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#a0616a"
    />
    <path
      d="M280.6414,560.35607a4.5069,4.5069,0,0,1-1.8186-4.34863c2.78247-18.34277-.41895-44.61914-3.59473-63.43262A24.79543,24.79543,0,0,1,302.7874,463.8131a79.86812,79.86812,0,0,1,9.91284,1.9541h0a24.59627,24.59627,0,0,1,18.582,24.46778c-.47485,18.1543,3.21142,40.80566,4.81836,49.70117a4.5186,4.5186,0,0,1-.83374,3.51074,4.39222,4.39222,0,0,1-3.09546,1.75c-17.80811,1.81739-37.01221,10.502-46.87451,15.5166a4.50062,4.50062,0,0,1-2.04956.501A4.42884,4.42884,0,0,1,280.6414,560.35607Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M328.7122,513.04455a4.4709,4.4709,0,0,1-2.47363-2.39746l-9.9314-22.94239a11.49973,11.49973,0,1,1,21.10718-9.13574l9.93164,22.94239a4.5057,4.5057,0,0,1-2.342,5.917l-12.8479,5.56152a4.46851,4.46851,0,0,1-3.44385.05469Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <circle cx="94.87339" cy="208.00327" r="24.56103" fill="#a0616a" />
    <path
      d="M286.11695,453.268a3.97442,3.97442,0,0,1-5.69016.32692,44.42586,44.42586,0,0,1-12.67228-16.54878c-3.19306-7.611-3.661-16.899-.027-24.03305,3.072-6.03078,8.58662-9.86968,14.09195-13.00724a22.99346,22.99346,0,0,1,6.84781-2.89428,8.9936,8.9936,0,0,1,7.154,1.40677,7.54425,7.54425,0,0,1,2.878,7.22724,22.35408,22.35408,0,0,1,9.80208-7.35654,11.36522,11.36522,0,0,1,7.55375-.50594c3.53554,1.18009,6.15253,4.75026,7.60882,8.58606a19.14072,19.14072,0,0,1,1.25546,9.26372,11.12859,11.12859,0,0,1-4.2691,7.67143c-2.88387,1.98988-6.59927,1.78778-9.95313.90708s-6.60888-2.37392-10.02126-2.79024-7.19422.4863-9.22732,3.52991c-1.43176,2.14338-1.7693,4.96687-2.06144,7.65827l-1.51947,13.99863a18.54776,18.54776,0,0,1-1.27762,5.83956A3.25061,3.25061,0,0,1,286.11695,453.268Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#2f2e41"
    />
    <path
      d="M374.95438,604.53826H335.08186a4.24661,4.24661,0,0,1-3.35526-1.62624,4.31805,4.31805,0,0,1-.80709-3.70456,102.32847,102.32847,0,0,0-.0003-47.52515,4.31916,4.31916,0,0,1,.80739-3.70456,4.24661,4.24661,0,0,1,3.35526-1.62624h39.87252a4.30257,4.30257,0,0,1,4.2371,3.6057,167.50643,167.50643,0,0,1,0,50.97535A4.30257,4.30257,0,0,1,374.95438,604.53826Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M344.35194,568.02812v-1.17188a3.00328,3.00328,0,0,1,3-3h20.92358a3.00328,3.00328,0,0,1,3,3v1.17188a3.00328,3.00328,0,0,1-3,3H347.35194A3.00328,3.00328,0,0,1,344.35194,568.02812Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#fff"
    />
    <path
      d="M344.35194,580.37284v-1.17236a3.00328,3.00328,0,0,1,3-3h20.92358a3.00328,3.00328,0,0,1,3,3v1.17236a3.00328,3.00328,0,0,1-3,3H347.35194A3.00328,3.00328,0,0,1,344.35194,580.37284Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#fff"
    />
    <path
      d="M357.21432,532.28513a4.39088,4.39088,0,0,1-.82066-3.766,104.00137,104.00137,0,0,0-.0003-48.30591,4.392,4.392,0,0,1,.821-3.766,4.31762,4.31762,0,0,1,3.41038-1.65235H401.152a4.37255,4.37255,0,0,1,4.30671,3.66494,170.23877,170.23877,0,0,1,0,51.81279,4.37255,4.37255,0,0,1-4.30671,3.66494H360.6247A4.31762,4.31762,0,0,1,357.21432,532.28513Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#6c63ff"
    />
    <path
      d="M393.33135,496.61659H371.982a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00328,3.00328,0,0,1,3-3h21.34936a3.00328,3.00328,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,393.33135,496.61659Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#fff"
    />
    <path
      d="M393.33135,509.164H371.982a3.00328,3.00328,0,0,1-3-3V504.89a3.00328,3.00328,0,0,1,3-3h21.34936a3.00328,3.00328,0,0,1,3,3V506.164A3.00328,3.00328,0,0,1,393.33135,509.164Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#fff"
    />
    <path
      d="M338.61941,554.28326a11.01879,11.01879,0,0,1-6.53393-6.15771,10.25939,10.25939,0,0,1-.35913-1.019l-52.322-14.89307a10.88006,10.88006,0,0,1-7.86109-10.34277l-.12768-20.20166,19.28589.51611,1.25683,12.13428L336.699,534.40631a10.56725,10.56725,0,0,1,1.31153-.66455h.00024a11.01,11.01,0,1,1,.60864,20.5415Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#a0616a"
    />
    <path
      d="M271.71557,510.5377a4.47088,4.47088,0,0,1-1.11986-3.25769l1.534-24.95261a11.49972,11.49972,0,1,1,22.956,1.41237L293.552,508.69248a4.5057,4.5057,0,0,1-4.76751,4.21507l-13.97358-.85916a4.46853,4.46853,0,0,1-3.09531-1.51069Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M496.60838,376.50788h-82a9.01031,9.01031,0,0,1-9-9v-89a9.0103,9.0103,0,0,1,9-9h82a9.01031,9.01031,0,0,1,9,9v89A9.01032,9.01032,0,0,1,496.60838,376.50788Zm-82-105a7.00785,7.00785,0,0,0-7,7v89a7.00786,7.00786,0,0,0,7,7h82a7.00787,7.00787,0,0,0,7-7v-89a7.00786,7.00786,0,0,0-7-7Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <path
      d="M646.60838,376.50788h-82a9.01031,9.01031,0,0,1-9-9v-89a9.0103,9.0103,0,0,1,9-9h82a9.01031,9.01031,0,0,1,9,9v89A9.01032,9.01032,0,0,1,646.60838,376.50788Zm-82-105a7.00785,7.00785,0,0,0-7,7v89a7.00786,7.00786,0,0,0,7,7h82a7.00787,7.00787,0,0,0,7-7v-89a7.00786,7.00786,0,0,0-7-7Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <path
      d="M431.1234,306.50788h-8a4.505,4.505,0,0,1-4.5-4.5v-8a4.505,4.505,0,0,1,4.5-4.5h8a4.505,4.505,0,0,1,4.5,4.5v8A4.505,4.505,0,0,1,431.1234,306.50788Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#6c63ff"
    />
    <path
      d="M431.1234,331.50788h-8a4.505,4.505,0,0,1-4.5-4.5v-8a4.505,4.505,0,0,1,4.5-4.5h8a4.505,4.505,0,0,1,4.5,4.5v8A4.505,4.505,0,0,1,431.1234,331.50788Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M489.59335,301.64484H452.244a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00328,3.00328,0,0,1,3-3h37.34936a3.00328,3.00328,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,489.59335,301.64484Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#6c63ff"
    />
    <path
      d="M581.8131,304.9394h-8a4.505,4.505,0,0,1-4.5-4.5v-8a4.505,4.505,0,0,1,4.5-4.5h8a4.505,4.505,0,0,1,4.5,4.5v8A4.505,4.505,0,0,1,581.8131,304.9394Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M640.28306,300.07636H602.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h37.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,640.28306,300.07636Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M640.28306,324.07636H570.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,640.28306,324.07636Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#6c63ff"
    />
    <path
      d="M640.28306,341.07636H570.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,640.28306,341.07636Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M640.28306,358.07636H570.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,640.28306,358.07636Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M796.60838,376.50788h-82a9.01031,9.01031,0,0,1-9-9v-89a9.0103,9.0103,0,0,1,9-9h82a9.01031,9.01031,0,0,1,9,9v89A9.01032,9.01032,0,0,1,796.60838,376.50788Zm-82-105a7.00785,7.00785,0,0,0-7,7v89a7.00786,7.00786,0,0,0,7,7h82a7.00787,7.00787,0,0,0,7-7v-89a7.00786,7.00786,0,0,0-7-7Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#3f3d56"
    />
    <path
      d="M790.28306,300.07636H752.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h37.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,790.28306,300.07636Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M790.28306,324.07636H720.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,790.28306,324.07636Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M790.28306,341.07636H720.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,790.28306,341.07636Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M790.28306,358.07636H720.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,790.28306,358.07636Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M489.59335,326.64484H452.244a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00328,3.00328,0,0,1,3-3h37.34936a3.00328,3.00328,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,489.59335,326.64484Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M431.1234,356.50788h-8a4.505,4.505,0,0,1-4.5-4.5v-8a4.505,4.505,0,0,1,4.5-4.5h8a4.505,4.505,0,0,1,4.5,4.5v8A4.505,4.505,0,0,1,431.1234,356.50788Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M489.59335,351.64484H452.244a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00328,3.00328,0,0,1,3-3h37.34936a3.00328,3.00328,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,489.59335,351.64484Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
    <path
      d="M736.605,295.50788c0-.02307.00342-.04535.00342-.06848a8.99989,8.99989,0,0,0-9-9v9.06848Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#6c63ff"
    />
    <path
      d="M726.60838,296.50788V287.4394a9,9,0,1,0,8.99658,9.06848Z"
      transform="translate(-206.08631 -223.44402)"
      fill="#ccc"
    />
  </svg>
);

export const addJobSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="833.22212"
    height="633.11008"
    viewBox="0 0 833.22212 633.11008"
    className="object-cover w-56 h-56"
  >
    <path
      d="M954.31369,708.90718c-27.3,20.79-60.56,41.85-93.26,51.78a132.151,132.151,0,0,1-29,5.61,87.68166,87.68166,0,0,1-27.45-2.12994c-23.6-5.87006-45.49-16.15-66.67-28.16-6.92-3.93-13.76-8.04-20.56-12.24-11.1-6.86-22.09-13.96-33.11-20.89-1.65-1.05-3.29-2.08-4.94-3.1h-.01q-3.99-2.505-8-4.94c-.47-.29-.94-.57-1.42-.86q-7.57508-4.59-15.41-8.97c-.64-.35-1.28-.71-1.92-1.06a337.75842,337.75842,0,0,0-31.12-15.23c-6.98005-2.94-14.05-5.59-21.19-7.85h-.01c-2.73-.86-5.47-1.67-8.22-2.41a172.03228,172.03228,0,0,0-25.13995-4.92,137.46074,137.46074,0,0,0-35.21.28c-.79.11-1.57.22-2.36.35-31.6,5.06-60.44,22.12-92.18,26.14-1.03.13-2.05.25-3.08.34-.44.04-.88.09-1.32.12a126.18456,126.18456,0,0,1-27.68-.68c-25.63-3.38-50.59-13.87-73.06-27.09-52.53-30.91-95.13-78.42-121.03-133.44a297.4536,297.4536,0,0,1-11.82995-28.93,278.46974,278.46974,0,0,1-15.67005-84.85c-2.43-100.36,54.6-201.05,144.14-246.44,89.55-45.39,207.35-30.38,279.57,39.35,43.81,42.3,69.16,99.62,102.13,150.83,32.97,51.2,79.83,100.11,140.27,107.57,30.79,3.8,63.01-3.55,92.52,6.01,16.34,5.3,30.28,15.44,41.51,28.36a127.18525,127.18525,0,0,1,18.78,29,141.92676,141.92676,0,0,1,8.73,23.54c.41,1.49.79,2.98,1.16,4.47C1026.97366,608.50715,1003.48367,671.44715,954.31369,708.90718Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#f2f2f2"
    />
    <rect
      x="159.66474"
      y="466.77221"
      width="145"
      height="51"
      rx="25.49997"
      fill="#3f3d56"
    />
    <path
      d="M434.05368,638.21717v42.43a125.55316,125.55316,0,0,1-29-.56v-41.87Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#3f3d56"
    />
    <path
      d="M531.67368,653.81715c-.79.11-1.57.22-2.36.35-31.6,5.06-60.44,22.12-92.18,26.14-1.03.13-2.05.25-3.08.34-.44.04-.88.09-1.32.12-7.75-7.59-15.5-15.67-22.78-24.04-3.95-4.53-7.77-9.15-11.36005-13.83-9.32-12.08-17.22-24.5-22.44-36.7.53-5.85-3.11-19.38-4.85-29.74l-.01-.01c-.74-4.35-1.14-8.13-.77-10.56,2.12006-14.02,13.57-12.41,17.09-26.15a77.49954,77.49954,0,0,1,10.11-7.11c.59-.35,1.19-.7,1.81-1.05l38.2-26.4,14.6,14.32,22.22,21.8,24.08,23.61005-20.44,20.65s17.07995,19.69,37.05,45.94C520.60367,638.54713,526.17368,646.07716,531.67368,653.81715Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#2f2e41"
    />
    <path
      d="M861.05368,638.21717v122.47a132.151,132.151,0,0,1-29,5.61v-128.08Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#3f3d56"
    />
    <rect
      x="595.66474"
      y="494.77221"
      width="145"
      height="51"
      rx="25.49997"
      fill="#3f3d56"
    />
    <path
      d="M669.89371,693.97718q-7.57508-4.59-15.41-8.97c-.64-.35-1.28-.71-1.92-1.06l3.44-1.47,9.28-3.99.09-.04.1.34Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#ffb6b6"
    />
    <path
      d="M684.2637,702.87715c-1.65-1.05-3.29-2.08-4.94-3.1h-.01q-3.99-2.505-8-4.94c-.47-.29-.94-.57-1.42-.86q-7.57508-4.59-15.41-8.97c-.64-.35-1.28-.71-1.92-1.06a337.75842,337.75842,0,0,0-31.12-15.23c-6.98005-2.94-14.05-5.59-21.19-7.85h-.01l-5.61-9.39-25.25-42.29-5.55-9.29s-18.93005,5.6-45.72,10.97c-9.75,1.96-20.53,3.88-31.84,5.5-12.09,1.72-24.77,3.09-37.37,3.75-6.01.32-12,.48-17.9.43-15.25-.1-29.9-1.53-42.73-4.93-4.28-4.02-17.18-9.49-26.42-14.5h-.01c-3.88-2.1-7.1-4.11-8.79-5.9-9.74-10.3-1.42-18.33-10.08-29.57a78.01753,78.01753,0,0,1,.63-12.34c.09-.68.18-1.37.29-2.07l2.73-46.36,20.28-2.68,30.86-4.08,33.43-4.42,3.68,28.82s26.03-1.32,59.01-.88c52.97.72,123.85,6,133.45,28.66.9,2.13,1.8,4.28,2.7,6.43q3.21,7.70994,6.36005,15.5c7.61,18.8,14.87,37.78,21.5,55.72,4.12,11.16,8,21.92,11.56994,31.99,2.87006,8.1,5.53,15.76,7.96,22.81,2.22,6.44,4.23,12.37,6.02,17.69C683.72366,701.26716,683.99368,702.08717,684.2637,702.87715Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#2f2e41"
    />
    <path
      d="M497.708,421.98972l-64.05525-54.40133,41.675-42.17691,29.075,47.62305,107.774,21.36695a20.90017,20.90017,0,1,1-2.96957,30.67819Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#a0616a"
    />
    <path
      d="M377.04235,296.09186c-7.40272,24.08263,63.09957,81.52,63.09957,81.52.78339-5.78164,40.75579,44.07344,43.4754,39.55842,7.72908-12.83228,16.2337-44.55195,25.15668-48.606,5.10285-2.31848-24.17069-34.54346-24.17069-34.54346s-14.93261-19.756-34.6486-44.79433a53.41188,53.41188,0,0,0-47.084-20.96122S384.44509,272.00927,377.04235,296.09186Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#3f3d56"
    />
    <circle cx="224.82523" cy="66.00911" r="43.32403" fill="#a0616a" />
    <path
      d="M429.87619,271.41226c9.33357,5.54889,14.91693,15.74413,18.122,26.1187a247.51563,247.51563,0,0,1,10.63632,59.137l3.3853,60.08943,41.92648,127.94729c-36.3363,30.746-172.919,6.98768-172.919,6.98768s-4.19264-1.3974,0-5.59019,8.27421-.47818,4.08162-4.67087-1.3024.47819.0952-3.7145,0-1.3974-1.39755-2.79509,10.81911-13.97556,10.81911-13.97556L333.44532,447.5033,319.46986,299.36313c16.77065-20.96323,50.49849-33.3209,50.49849-33.3209l8.71485-15.68672,43.57423,3.48593Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#3f3d56"
    />
    <path
      d="M443.928,176.43671a20.07763,20.07763,0,0,1,7.13274,10.01951,141.01837,141.01837,0,0,0,.09279-19.65944c-.51064-4.60083-3.74974-5.63572-7.50039-6.83487-3.38718-1.0834-7.21783-2.30721-8.24844-6.77356-13.61863-13.24844-37.32575-12.58037-53.55037-7.95911-8.9276,2.543-14.0646,5.81022-14.52418,7.7242l-.09529.394-.36256.18128c-6.88167,3.44083-8.22124,14.30631-8.13272,22.8152.16765,16.03141,5.70292,34.239,8.8212,35.7181.1651.07829.20679.04764.27828-.0119h0c4.6953-3.75234,10.4238.28515,12.94119,2.48a45.05892,45.05892,0,0,1,40.34614-38.21931,70.7027,70.7027,0,0,0,9.72421-1.63058,20.47249,20.47249,0,0,1,4.96762-.6519A14.13716,14.13716,0,0,1,443.928,176.43671Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#2f2e41"
    />
    <path
      d="M389.33783,487.26235,376.085,480.475a3.63228,3.63228,0,0,1,3.27636-6.39728l13.25279,6.78739a3.63228,3.63228,0,0,1-3.27636,6.39728Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#6c63ff"
    />
    <path
      d="M344.735,469.078l-21.80115-81.16214,58.25865-11.0284-3.13289,55.7091,76.54694,78.81836A20.90017,20.90017,0,1,1,434.732,534.97276Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#a0616a"
    />
    <path
      d="M316.97223,296.91613c-19.77507,15.61152,5.60629,102.93512,5.60629,102.93512,3.9297-4.31264,8.49619,59.425,13.29948,57.25472,13.65136-6.16814,38.67257-27.43807,48.3187-25.70436,5.51648.99153-.26308-42.15927-.26308-42.15927s-1.063-24.74173-3.06092-56.54811a53.412,53.412,0,0,0-26.83569-44.0014S336.74735,281.3047,316.97223,296.91613Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#3f3d56"
    />
    <path
      d="M1002.3837,520.45716l-791.42-.9a297.4536,297.4536,0,0,1-11.82995-28.93l784.47.83A127.18525,127.18525,0,0,1,1002.3837,520.45716Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#3f3d56"
    />
    <path
      d="M750.31369,730.27717q-6.135,2.97-12.38,5.73c-6.92-3.93-13.76-8.04-20.56-12.24l25.22-11.08Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#ffb6b6"
    />
    <path
      d="M934.1937,592.47718q-6.15006,8.25-12.69,16.17-6.03,7.305-12.38,14.33c-1.75,1.94-3.52,3.87-5.33,5.78q-4.995,5.355-10.21,10.53c-6.26,1.97-18.94,5.44-32.53,8.68-9.89,2.35-20.26,4.58-29,6a104.48641,104.48641,0,0,1-14.81,1.58c-5.9.05-11.89-.11-17.9-.43-6.78-.36-13.57-.92-20.28-1.64-17.45-1.83-34.35-4.68-48.93-7.61-5.34-1.06-10.36005-2.14-14.98-3.19-8.75-1.97-16.06995-3.81-21.37-5.21-5.97-1.56-9.37-2.57-9.37-2.57l-14.95,25.04-7.75994,12.99-5.7,9.55-1.52,2.53c-.64-.35-1.28-.71-1.92-1.06a337.75842,337.75842,0,0,0-31.12-15.23c-6.98005-2.94-14.05-5.59-21.19-7.85h-.01c-2.73-.86-5.47-1.67-8.22-2.41q1.29008-3.465,2.61005-6.98,2.45992-6.555,5.02-13.23c3.69-9.63995,7.51-19.42,11.44-29.15,3.22-7.99,6.5-15.95,9.82-23.8,1.53-3.62,4.64-6.8,8.99-9.59a52.32312,52.32312,0,0,1,6.49005-3.48c12.44995-5.67,30.79-9.36,51-11.73,1.67-.19,3.35-.38,5.04-.56,16.73-1.76,34.48-2.67,51.08-3.09,8.31-.21,16.33-.29,23.79-.3q3.615,0,7.03.02h.01c6.15.03,11.81.12,16.79.22.97.02,1.91.05,2.83.07h.01c.62.01,1.23005.03,1.83.04,10.89.28,17.57.62,17.57.62l2.59-20.32.53-4.1.5-3.9v-.01l.06-.49.41-.01,7.2-.14,60.52-1.18,33.06-.65-5.24,22.46-8.65,37.06C905.23367,592.09718,934.49368,587.50715,934.1937,592.47718Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#2f2e41"
    />
    <path
      d="M757.77365,726.57716c-2.47,1.26-4.96,2.5-7.46,3.7q-6.135,2.97-12.38,5.73c-6.92-3.93-13.76-8.04-20.56-12.24-11.1-6.86-22.09-13.96-33.11-20.89-1.65-1.05-3.29-2.08-4.94-3.1h-.01c-4.54-6.74-9.17-13.76-13.84-20.99-.07-.1-.13-.2-.19-.30005q-1.79993-2.75994-3.58-5.56c-7.47-11.7-14.98005-23.9-22.27-36.36l18.46-8.62,34.31994-16.02,5.92-2.75994,17.02,33.50994,15.18005,29.89,3.37994,6.65Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#2f2e41"
    />
    <path
      d="M762.94619,565.69478l87.50055-84.38371,24.25231-68.66967L838.92615,397.086c-18.37452,17.6014-38.23657,72.42429-38.23657,72.42429L740.4768,546.11471c-.4692.1607-.93718.33133-1.39933.53544a17.23112,17.23112,0,1,0,23.86872,19.04463Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#ffb8b8"
    />
    <path
      d="M828.86025,404.75323l45.37541,14.40513.171-.02847c16.88869-2.829,30.9027-30.41247,39.68439-53.05389a31.59624,31.59624,0,0,0-16.08739-40.02451h0a31.66589,31.66589,0,0,0-33.1649,4.18441l-23.30057,19.03929Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#6c63ff"
    />
    <path
      d="M934.01154,593.48992l.26505-.35223c14.15727-18.77628-5.2058-65.27339-8.13309-72.01689l13.16362-3.89925-2.29443-25.06377-1.10513-11.1761L946.1425,471.262l.03447-.24807,7.03885-50.09245,9.20992-33.1538A73.28485,73.28485,0,0,0,943.465,316.23291L924.63751,297.54l-22.5563-35.30637-37.8193-.97639-13.59975,24.15893a55.14122,55.14122,0,0,0-44.103,55.17854l1.28036,64.31568L794.26928,484.5975l-.3807,14.47486-13.31921,14.05527,2.46244,14.20951-9.963,3.9686-4.87538,20.19138c-1.862,2.32956-14.15633,17.87008-14.26818,22.20243-.01679.65043.44707,1.29151,1.45938,2.01562,7.958,5.69367,52.43741,18.031,72.456,10.61389,21.32343-7.894,104.89466,6.93176,105.7366,7.08256Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#6c63ff"
    />
    <circle cx="696.47956" cy="66.8854" r="50.1036" fill="#ffb8b8" />
    <path
      d="M873.75623,251.10436a136.26665,136.26665,0,0,0,23.18064-1.62c7.59922-1.54533,24.59947-14.00321,29.33857-21.4998h0c3.52093-5.56989,5.77245-14.26768,7.04151-20.583a60.91432,60.91432,0,0,0-10.65371-48.35812,19.24494,19.24494,0,0,0-7.77317-6.63435c-.27844-.10888-.565-.20537-.85454-.28786a23.74464,23.74464,0,0,1-11.81175-7.30889,19.58589,19.58589,0,0,0-1.96357-2.02086,29.2483,29.2483,0,0,0-12.20276-6.12728c-7.25394-1.94141-17.752-.01431-31.20184,5.73333-6.75724,2.88773-14.14724,1.84266-19.83226.60946a1.86014,1.86014,0,0,0-1.75136.6,13.13152,13.13152,0,0,1-8.92485,3.55766c-2.032.08923-4.16392,2.90278-6.74081,6.57174-.5847.83285-1.267,1.80487-1.75914,2.39607l-.06607-1.13583-1.14927,1.26711a15.93481,15.93481,0,0,0,7.19417,25.96108,31.07641,31.07641,0,0,0,6.2501.996c1.27917.11508,2.60233.23413,3.87032.45413a24.02454,24.02454,0,0,1,17.8528,15.59016,6.28282,6.28282,0,0,0,9.483,3.05031,10.3807,10.3807,0,0,1,9.459-1.72243,6.89123,6.89123,0,0,1,3.04595,3.455,8.98031,8.98031,0,0,0,3.40768,3.97567c5.14968,2.65506,5.50461,14.41709,3.6551,24.22217-1.78279,9.45123-5.307,16.28835-8.57,16.62634-2.512.25986-2.79926.43831-2.98114.88257l-.16246.3977.28218.372A10.2608,10.2608,0,0,0,873.75623,251.10436Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#2f2e41"
    />
    <path
      d="M841.30225,389.605l-19.31863-38.5155c-28.04093,1.91544-83.6946,34.33734-83.6946,34.33734L632.99029,407.75242a19.02347,19.02347,0,1,0,5.18645,32.5081L772.123,430.65926Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#ffb8b8"
    />
    <path
      d="M833.5105,395.92917l50.45383-37.49451,8.88732-32.02709a34.982,34.982,0,0,0-10.52129-35.39425h0a34.89929,34.89929,0,0,0-47.624,1.53654c-18.98656,18.95124-40.64185,45.39-35.99009,63.72324l.04709.18584Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#6c63ff"
    />
    <rect x="452.66474" y="362.77221" width="135" height="18" fill="#fff" />
    <rect x="107.66474" y="362.77221" width="135" height="18" fill="#fff" />
    <path
      d="M441.81766,409.65536v85.704a4.12233,4.12233,0,0,0,4.11906,4.11906H572.94121a4.12231,4.12231,0,0,0,4.11906-4.11906v-85.704a4.12431,4.12431,0,0,0-4.11906-4.11451H445.93672A4.12433,4.12433,0,0,0,441.81766,409.65536Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#3f3d56"
    />
    <path
      d="M444.79712,410.72172v83.58039a2.20522,2.20522,0,0,0,2.20141,2.20141H571.884a2.2052,2.2052,0,0,0,2.20141-2.20141V410.72172a2.2062,2.2062,0,0,0-2.20141-2.206H446.99853A2.20622,2.20622,0,0,0,444.79712,410.72172Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#6c63ff"
    />
    <path
      d="M439.16149,512.0739a2.46987,2.46987,0,0,0,1.94346.92983h135.992A2.5024,2.5024,0,0,0,579.543,509.988l-2.111-10.02722a2.50692,2.50692,0,0,0-1.55811-1.81784,2.42467,2.42467,0,0,0-.888-.1675H443.20758a2.42455,2.42455,0,0,0-.888.1675,2.5067,2.5067,0,0,0-1.5581,1.81784l-2.111,10.02722A2.50046,2.50046,0,0,0,439.16149,512.0739Z"
      transform="translate(-183.38894 -133.44496)"
      fill="#2f2e41"
    />
    <rect
      x="569.83152"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(960.46258 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="561.45453"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(943.70862 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="553.07755"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(926.95465 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="544.70057"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(910.20069 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="536.32359"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(893.44673 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="527.94661"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(876.69276 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="519.56962"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(859.9388 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="511.19264"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(843.18483 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="502.81566"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(826.43087 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="494.43868"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(809.67691 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="486.0617"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(792.92294 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="477.68471"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(776.16898 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="469.30773"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(759.41502 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="460.93075"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(742.66105 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="452.55377"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(725.90709 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="444.17679"
      y="499.78134"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(709.15312 868.63082) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="569.9305"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(960.66055 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="561.55352"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(943.90659 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="553.17654"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(927.15262 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="544.79956"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(910.39866 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="536.42257"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(893.6447 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="528.04559"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(876.89073 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="519.66861"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(860.13677 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="511.29163"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(843.38281 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="502.91465"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(826.62884 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="494.53766"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(809.87488 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="486.16068"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(793.12091 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="477.7837"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(776.36695 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="469.40672"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(759.61299 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="461.02974"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(742.85902 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="452.65276"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(726.10506 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="444.27577"
      y="503.96984"
      width="4.18849"
      height="2.51309"
      rx="0.48819"
      transform="translate(709.3511 877.00781) rotate(-180)"
      fill="#3f3d56"
    />
    <rect
      x="500.40155"
      y="508.99602"
      width="33.50793"
      height="2.51309"
      rx="0.48819"
      transform="translate(850.92209 887.06018) rotate(-180)"
      fill="#3f3d56"
    />
  </svg>
);

export const manageJobsSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="753.87469"
    height="703.82827"
    viewBox="0 0 753.87469 703.82827"
    className="object-cover w-56 h-56"
  >
    <path
      d="M578.47505,103.95771l-23.06843,12.58664L271.19846,271.61447,248.13,284.2011a48.1793,48.1793,0,0,0-19.1955,65.29607L440.57765,737.39072a48.17922,48.17922,0,0,0,65.296,19.19561l.05958-.03251L836.15907,576.37545l.05958-.03251a48.17924,48.17924,0,0,0,19.19553-65.296L643.77106,123.15338A48.17929,48.17929,0,0,0,578.47505,103.95771Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#f2f2f2"
    />
    <path
      d="M585.11115,116.11916l-27.323,14.908L282.08828,281.455,254.7657,296.36278a34.30947,34.30947,0,0,0-13.66965,46.4988L452.73916,730.75513a34.30947,34.30947,0,0,0,46.4988,13.66952l.05958-.0325L829.5234,564.21377l.06-.03274a34.30935,34.30935,0,0,0,13.66926-46.49851L631.60954,129.789A34.30936,34.30936,0,0,0,585.11115,116.11916Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#fff"
    />
    <path
      d="M589.66653,236.52147,466.505,303.72109a8.01411,8.01411,0,1,1-7.677-14.07012l123.16157-67.19962a8.01411,8.01411,0,1,1,7.677,14.07012Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#f2f2f2"
    />
    <path
      d="M631.641,244.43106,479.45984,327.46442a8.01411,8.01411,0,0,1-7.677-14.07012l152.18119-83.03336a8.01411,8.01411,0,1,1,7.677,14.07012Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#f2f2f2"
    />
    <path
      d="M415.87223,275.74837l-113.5479,61.95419a3.84082,3.84082,0,0,0-1.53151,5.21006L349.14436,431.53a3.84075,3.84075,0,0,0,5.21,1.5317l113.5479-61.95419a3.84075,3.84075,0,0,0,1.53153-5.21l-48.35154-88.61735A3.84081,3.84081,0,0,0,415.87223,275.74837Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#f2f2f2"
    />
    <path
      d="M650.7763,348.96263,483.723,440.11051a8.01411,8.01411,0,1,1-7.677-14.07012l167.05327-91.14788a8.01411,8.01411,0,1,1,7.677,14.07012Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#f2f2f2"
    />
    <path
      d="M692.7508,356.87223,496.67791,463.85384a8.01411,8.01411,0,0,1-7.677-14.07012L685.07384,342.80211a8.01411,8.01411,0,1,1,7.677,14.07012Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#f2f2f2"
    />
    <circle cx="197.03853" cy="382.67177" r="34" fill="#f2f2f2" />
    <path
      d="M928.81234,263.78816H552.494a48.17927,48.17927,0,0,0-48.125,48.12512V753.78907a48.17922,48.17922,0,0,0,48.125,48.12506H928.81234a48.17922,48.17922,0,0,0,48.125-48.12506V311.91328A48.17927,48.17927,0,0,0,928.81234,263.78816Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#e6e6e6"
    />
    <path
      d="M928.81283,277.64235H552.494a34.30947,34.30947,0,0,0-34.271,34.27093V753.78907A34.30947,34.30947,0,0,0,552.494,788.06H928.81283a34.30936,34.30936,0,0,0,34.27051-34.27088V311.91328A34.30937,34.30937,0,0,0,928.81283,277.64235Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#fff"
    />
    <path
      d="M875.14319,385.51745H734.84151a8.01411,8.01411,0,0,1,0-16.02823H875.14319a8.01412,8.01412,0,1,1,0,16.02823Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#6c63ff"
    />
    <path
      d="M908.20141,412.56508H734.84151a8.01411,8.01411,0,1,1,0-16.02822h173.3599a8.01411,8.01411,0,0,1,0,16.02822Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#6c63ff"
    />
    <path
      d="M703.79234,336.71073H574.44224a3.8408,3.8408,0,0,0-3.83984,3.84v100.95a3.84075,3.84075,0,0,0,3.83984,3.84h129.3501a3.84076,3.84076,0,0,0,3.83984-3.84v-100.95A3.84081,3.84081,0,0,0,703.79234,336.71073Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#e6e6e6"
    />
    <path
      d="M609.92406,398.70111a34.087,34.087,0,0,1-8.804,23.076c-5.656,6.20712-14.07618,10.3236-22.57327,8.62043-7.82416-1.56829-14.18219-8.4067-13.389-16.6795a12.356,12.356,0,0,1,15.2668-11.09515c7.43265,1.92885,10.39415,12.64095,4.20051,17.669-1.4862,1.2065-3.62136-.90359-2.12132-2.12132,4.0944-3.32385,2.8295-10.5954-2.11244-12.419-5.75371-2.12311-11.84978,2.44324-12.26355,8.32554-.49057,6.97428,4.85221,12.22646,11.40422,13.463,7.08789,1.3377,14.11532-2.29,18.91808-7.29718a30.95507,30.95507,0,0,0,8.474-21.54183,1.5009,1.5009,0,0,1,3,0Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#2f2e41"
    />
    <circle cx="416.15529" cy="266.1673" r="53.51916" fill="#6c63ff" />
    <path
      d="M636.47981,387.08916l-.05566-2c3.7207-.10352,7.001-.33692,9.46582-2.1377a6.14794,6.14794,0,0,0,2.38134-4.52832,3.51432,3.51432,0,0,0-1.15283-2.89453c-1.63623-1.38184-4.269-.93457-6.188-.05469l-1.65478.75879,3.17334-23.19043,1.98144.27149-2.69922,19.72656c2.60743-.7666,5.02344-.43652,6.67823.96094a5.471,5.471,0,0,1,1.86035,4.49218,8.13264,8.13264,0,0,1-3.2002,6.07325C643.90266,386.88115,639.78694,386.99638,636.47981,387.08916Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#2f2e41"
    />
    <rect
      x="431.16715"
      y="256.92907"
      width="10.77148"
      height="2"
      fill="#2f2e41"
    />
    <rect
      x="397.16715"
      y="256.92907"
      width="10.77148"
      height="2"
      fill="#2f2e41"
    />
    <path
      d="M609.57212,445.34074a53.00636,53.00636,0,0,1,12.89014-5.93,8.56789,8.56789,0,0,1,.02-4.71,9.42609,9.42609,0,0,1,9.12988-6.63h13.04a9.45955,9.45955,0,0,1,9.15039,6.64,8.532,8.532,0,0,1,.01953,4.7,53.16732,53.16732,0,0,1,12.89014,5.93Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#2f2e41"
    />
    <path
      d="M700.52232,344.39072a11.57143,11.57143,0,0,0-3.52979-2.87,8.36739,8.36739,0,0,0-3.8501-.95,8.77158,8.77158,0,0,0-5.10986,1.72c-4.07031,2.88-6.89014,9.09-6.89014,16.28,0,9.02,4.43995,16.5,10.21,17.80005a8.25321,8.25321,0,0,0,1.79.2c6.60987,0,12-8.07,12-18C705.14243,352.81077,703.33238,347.68076,700.52232,344.39072Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#3f3d56"
    />
    <path
      d="M590.6024,341.86076h-.00977a8.57836,8.57836,0,0,0-4.4502-1.29,8.36738,8.36738,0,0,0-3.85009.95,11.57143,11.57143,0,0,0-3.52979,2.87l-.01025.01c-2.79981,3.29-4.60987,8.42-4.60987,14.17,0,7.76,3.27979,14.38,7.87989,16.91a8.54175,8.54175,0,0,0,4.12011,1.09,7.72431,7.72431,0,0,0,.96-.06h.00976c6.16016-.74,11.03027-8.5,11.03027-17.94C598.14243,351.01072,595.01255,344.52073,590.6024,341.86076Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#3f3d56"
    />
    <path
      d="M582.77242,373.76a1.50127,1.50127,0,0,0,1.42151-1.98,58.49864,58.49864,0,1,1,112.68726-6.5747,1.50006,1.50006,0,0,0,2.93554.61914A61.50091,61.50091,0,1,0,581.35116,372.739,1.50077,1.50077,0,0,0,582.77242,373.76Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#3f3d56"
    />
    <path
      d="M666.10324,329.57746c2.11924,2.89278,1.07447,6.79121-1.15837,9.28528-2.90548,3.24541-7.53877,3.45016-11.5618,2.8478-4.51431-.67591-9.3026-2.7909-13.87293-1.3656-3.89537,1.2148-6.67418,4.74793-10.7211,5.63537-3.589.787-7.88081-.25477-9.139-4.08016-.60459-1.83823,2.29142-2.6261,2.89284-.79751.81395,2.47478,4.32865,2.42543,6.34145,1.74012,3.22689-1.09867,5.71374-3.77105,8.8854-5.04749,3.73933-1.50491,7.79621-.82549,11.60323.03181,3.58831.808,7.718,2.006,11.29267.49665,2.64515-1.1169,4.74985-4.635,2.84717-7.23211-1.14219-1.5591,1.45985-3.05738,2.59042-1.51416Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#2f2e41"
    />
    <path
      d="M874.932,513.49157H684.63034a8.01411,8.01411,0,1,1,0-16.02823H874.932a8.01412,8.01412,0,0,1,0,16.02823Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#e6e6e6"
    />
    <path
      d="M907.99023,540.5392H684.63034a8.01412,8.01412,0,1,1,0-16.02823H907.99023a8.01412,8.01412,0,1,1,0,16.02823Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#e6e6e6"
    />
    <path
      d="M874.932,610.705H684.63034a8.01411,8.01411,0,1,1,0-16.02822H874.932a8.01411,8.01411,0,1,1,0,16.02822Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#e6e6e6"
    />
    <path
      d="M907.99023,637.75267H684.63034a8.01411,8.01411,0,1,1,0-16.02823H907.99023a8.01411,8.01411,0,1,1,0,16.02823Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#e6e6e6"
    />
    <circle cx="386.2497" cy="420.61448" r="34" fill="#e6e6e6" />
    <circle cx="386.2497" cy="518.61448" r="34" fill="#e6e6e6" />
    <path
      d="M874.932,708.705H684.63034a8.01411,8.01411,0,1,1,0-16.02822H874.932a8.01411,8.01411,0,1,1,0,16.02822Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#e6e6e6"
    />
    <path
      d="M907.99023,735.75267H684.63034a8.01411,8.01411,0,1,1,0-16.02823H907.99023a8.01411,8.01411,0,1,1,0,16.02823Z"
      transform="translate(-223.06266 -98.08587)"
      fill="#e6e6e6"
    />
    <circle cx="386.2497" cy="616.61448" r="34" fill="#e6e6e6" />
  </svg>
);

export const addOfferSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="813"
    height="423.80418"
    viewBox="0 0 813 423.80418"
    className="object-cover w-56 h-56"
  >
    <path
      d="M951.08008,652.12182a45.62764,45.62764,0,0,1-3.8999,7.78027c-.43018.68018-.88038,1.3501-1.36036,2H905.98c-.27978-.21-.5498-.43018-.83008-.64014-1.3999-.10986-2.79-.25-4.1499-.3999.14014-.12012.29-.24023.43018-.35986-.72022.06006-1.43995.11963-2.16016.1499.56982.06982,1.1499.14014,1.73.21-.43018.3501-.87012.7002-1.31982,1.04h-1.74024l1.16992-1.25c-.92968.02979-1.85009.04981-2.77978.02979l2.91015-.77979,3.13965-.83984c.12012.09961.25.1997.37012.2998a53.22011,53.22011,0,0,0,3.68018-76.39014,71.33813,71.33813,0,0,1,30.35009,46.01026,41.55661,41.55661,0,0,1,.39991,13.7998c.35009-.38964.6997-.77978,1.03955-1.17968A54.16,54.16,0,0,0,949.66016,597.012,71.38083,71.38083,0,0,1,951.08008,652.12182Z"
      transform="translate(-193.5 -238.09791)"
      fill="#e6e6e6"
    />
    <circle cx="558.84183" cy="51.48317" r="28.08173" fill="#2f2e41" />
    <polygon
      points="649.907 412.099 637.647 412.098 631.814 364.81 649.909 364.811 649.907 412.099"
      fill="#ffb8b8"
    />
    <path
      d="M628.88964,408.59511h23.64387a0,0,0,0,1,0,0V423.482a0,0,0,0,1,0,0H614.00278a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,628.88964,408.59511Z"
      fill="#2f2e41"
    />
    <polygon
      points="524.845 410.494 513.008 407.302 519.685 360.126 537.155 364.837 524.845 410.494"
      fill="#ffb8b8"
    />
    <path
      d="M696.876,640.62164H720.5199a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H681.98917a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,696.876,640.62164Z"
      transform="translate(-0.611 -398.30924) rotate(15.08956)"
      fill="#2f2e41"
    />
    <path
      d="M741.57717,431.39381,705.07092,638.26255l18.72115,3.74423,58.97164-146.025,43.9947,145.08894,22.46539-2.80818s-10.29664-207.80479-43.05865-225.58989Z"
      transform="translate(-193.5 -238.09791)"
      fill="#2f2e41"
    />
    <path
      d="M683.68176,505.3971a10.05581,10.05581,0,0,0,3.59591-14.99417l20.13339-29.5233-18.56746-.28816-16.27242,28.08a10.11027,10.11027,0,0,0,11.11058,16.72562Z"
      transform="translate(-193.5 -238.09791)"
      fill="#ffb8b8"
    />
    <path
      d="M816.60194,468.89086a10.05578,10.05578,0,0,0,3.59592-14.99417l20.13339-29.52331-18.56746-.28816-16.27242,28.08a10.11027,10.11027,0,0,0,11.11057,16.72563Z"
      transform="translate(-193.5 -238.09791)"
      fill="#ffb8b8"
    />
    <circle cx="559.55677" cy="60.56367" r="24.56103" fill="#ffb8b8" />
    <path
      d="M806.16515,336.852c-.93606-3.74423-16.849-2.80817-16.849-2.80817s-17.91856-4.11948-17.749-3.60437c-15.09987-1.1623-33.51936,4.5066-36.20207,5.36633-5.4886,1.51424-15.97532,6.14623-23.538,9.55316a4.38074,4.38074,0,0,0-2.21091,5.75542l23.06847,52.66575,3.2762,34.16611,75.82067-23.40144C793.06034,407.05631,807.1012,340.59622,806.16515,336.852Z"
      transform="translate(-193.5 -238.09791)"
      fill="#6c63ff"
    />
    <polygon
      points="606.113 99.69 612.336 98.127 646.363 165.214 648.235 203.593 626.706 186.744 626.706 171.767 604.241 142.749 606.113 99.69"
      fill="#6c63ff"
    />
    <path
      d="M717.7077,342.93637l-7.20562,3.20249a2.50317,2.50317,0,0,0-1.449,1.85561L694.77429,429.5217l-14.04087,41.18654,18.72115,9.36057,21.52933-51.48317,7.48846-37.44231Z"
      transform="translate(-193.5 -238.09791)"
      fill="#6c63ff"
    />
    <path
      d="M727.29984,287.08328c4.985-1.63684,10.2436.92924,15.11328,2.88268,4.79927,1.9252,9.92693,3.31258,15.089,3.0093s10.38267-2.48332,13.50355-6.60636,3.67009-10.30225.58085-14.449c-2.03364-2.72985-5.27861-4.2874-8.52808-5.30162-6.94293-2.167-14.71345-2.24184-21.313.81534s-11.7747,9.51567-12.31843,16.76856"
      transform="translate(-193.5 -238.09791)"
      fill="#2f2e41"
    />
    <path
      d="M751.59383,267.51935c-1.67441-11.33607,4.53512-23.106,13.01014-27.32821s18.41768-1.73148,26.05251,4.55592,13.2005,15.94924,17.34295,26.21986,7.01073,21.27122,10.38593,31.98265c2.99761,9.5131,6.51648,19.03195,12.20956,26.30764s13.97217,12.0102,21.90508,10.01114c-6.51732,8.7627-18.507,7.24543-25.94521-.24973s-11.305-19.21018-14.34878-30.61366-5.69221-23.33512-11.5804-32.785c-10.47519-16.81146-31.03124-21.86216-45.71131-11.23144"
      transform="translate(-193.5 -238.09791)"
      fill="#2f2e41"
    />
    <rect y="421.80418" width="813" height="2" fill="#3f3d56" />
    <path
      d="M620.04,468.00979H265.21616A10.72787,10.72787,0,0,1,254.5,457.29363V372.75506a10.72787,10.72787,0,0,1,10.71616-10.71615H620.04a10.72787,10.72787,0,0,1,10.71616,10.71615v84.53857A10.72787,10.72787,0,0,1,620.04,468.00979ZM265.21616,364.42027a8.3449,8.3449,0,0,0-8.33479,8.33479v84.53857a8.3449,8.3449,0,0,0,8.33479,8.33479H620.04a8.3449,8.3449,0,0,0,8.33479-8.33479V372.75506a8.3449,8.3449,0,0,0-8.33479-8.33479Z"
      transform="translate(-193.5 -238.09791)"
      fill="#3f3d56"
    />
    <path
      d="M602.77509,450.14953h-320.294a1.19069,1.19069,0,1,1,0-2.38137h320.294a1.19069,1.19069,0,1,1,0,2.38137Z"
      transform="translate(-193.5 -238.09791)"
      fill="#e6e6e6"
    />
    <path
      d="M316.41557,410.857h-26.195a1.19069,1.19069,0,0,0,0,2.38137h11.90684v21.05441a5.99643,5.99643,0,0,0,5.543,6.06156c.07442.00349.15116.00465.22907.00465a5.76158,5.76158,0,0,0,4.70576-2.593,1.19022,1.19022,0,0,0-1.90463-1.42789,3.379,3.379,0,0,1-2.9302,1.63719,3.59514,3.59514,0,0,1-3.26159-3.68252V413.23832h11.90684a1.19069,1.19069,0,1,0,0-2.38137Z"
      transform="translate(-193.5 -238.09791)"
      fill="#6c63ff"
    />
    <circle cx="212.21688" cy="266.82308" r="9.52547" fill="#e6e6e6" />
    <circle cx="249.12808" cy="266.82308" r="9.52547" fill="#6c63ff" />
    <circle cx="286.03929" cy="266.82308" r="9.52547" fill="#e6e6e6" />
    <path
      d="M321.97522,439.4708a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z"
      transform="translate(-193.5 -238.09791)"
      fill="#3f3d56"
    />
    <path
      d="M331.5007,439.4708a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z"
      transform="translate(-193.5 -238.09791)"
      fill="#3f3d56"
    />
    <path
      d="M341.02617,439.4708a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z"
      transform="translate(-193.5 -238.09791)"
      fill="#3f3d56"
    />
  </svg>
);

export const manageOffersSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="728.34646"
    height="523.59055"
    viewBox="0 0 728.34646 523.59055"
    className="object-cover w-56 h-56"
  >
    <path
      d="M947.17323,711.20472h-437a17.01916,17.01916,0,0,1-17-17v-489a17.01917,17.01917,0,0,1,17-17h437a17.01917,17.01917,0,0,1,17,17v489A17.01916,17.01916,0,0,1,947.17323,711.20472Zm-437-521a15.017,15.017,0,0,0-15,15v489a15.017,15.017,0,0,0,15,15h437a15.017,15.017,0,0,0,15-15v-489a15.017,15.017,0,0,0-15-15Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#3f3d56"
    />
    <rect x="258.34646" y="41.59033" width="469" height="2" fill="#3f3d56" />
    <circle cx="281.34646" cy="22" r="8" fill="#6c63ff" />
    <circle cx="306.34646" cy="22" r="8" fill="#6c63ff" />
    <circle cx="331.34646" cy="22" r="8" fill="#6c63ff" />
    <path
      d="M884.67323,303.20472h-312a13,13,0,0,1,0-26h312a13,13,0,0,1,0,26Zm-312-24a11,11,0,0,0,0,22h312a11,11,0,0,0,0-22Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#3f3d56"
    />
    <path
      d="M807.52756,405.79528h-142a8,8,0,0,1,0-16h142a8,8,0,0,1,0,16Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#ccc"
    />
    <path
      d="M889.52756,432.79528h-224a8,8,0,0,1,0-16h224a8,8,0,0,1,0,16Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#ccc"
    />
    <path
      d="M612.3189,434.29528h-43a9.51081,9.51081,0,0,1-9.5-9.5v-27a9.5108,9.5108,0,0,1,9.5-9.5h43a9.5108,9.5108,0,0,1,9.5,9.5v27A9.51081,9.51081,0,0,1,612.3189,434.29528Zm-43-44a7.50835,7.50835,0,0,0-7.5,7.5v27a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-27a7.50835,7.50835,0,0,0-7.5-7.5Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#ccc"
    />
    <path
      d="M807.52756,500.79528h-142a8,8,0,0,1,0-16h142a8,8,0,0,1,0,16Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#ccc"
    />
    <path
      d="M889.52756,527.79528h-224a8,8,0,1,1,0-16h224a8,8,0,0,1,0,16Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#ccc"
    />
    <path
      d="M612.3189,529.29528h-43a9.51081,9.51081,0,0,1-9.5-9.5v-27a9.5108,9.5108,0,0,1,9.5-9.5h43a9.5108,9.5108,0,0,1,9.5,9.5v27A9.51081,9.51081,0,0,1,612.3189,529.29528Zm-43-44a7.50835,7.50835,0,0,0-7.5,7.5v27a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-27a7.50835,7.50835,0,0,0-7.5-7.5Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#ccc"
    />
    <path
      d="M807.52756,595.79528h-142a8,8,0,0,1,0-16h142a8,8,0,0,1,0,16Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#f2f2f2"
    />
    <path
      d="M889.52756,622.79528h-224a8,8,0,0,1,0-16h224a8,8,0,0,1,0,16Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#f2f2f2"
    />
    <path
      d="M612.3189,624.29528h-43a9.51081,9.51081,0,0,1-9.5-9.5v-27a9.5108,9.5108,0,0,1,9.5-9.5h43a9.5108,9.5108,0,0,1,9.5,9.5v27A9.51081,9.51081,0,0,1,612.3189,624.29528Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#f2f2f2"
    />
    <path
      d="M590.8189,426.03362l-2.31545-2.08878c-8.22262-7.54934-13.65109-12.44922-13.65109-18.553a8.68327,8.68327,0,0,1,8.78159-8.83494,9.44539,9.44539,0,0,1,7.18495,3.37347,9.445,9.445,0,0,1,7.18494-3.37347,8.68328,8.68328,0,0,1,8.78159,8.83494c0,6.10382-5.42854,11.00371-13.65108,18.553Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#6c63ff"
    />
    <path
      d="M590.8189,521.03362l-2.31545-2.08878c-8.22262-7.54934-13.65109-12.44922-13.65109-18.553a8.68327,8.68327,0,0,1,8.78159-8.83494,9.44539,9.44539,0,0,1,7.18495,3.37347,9.445,9.445,0,0,1,7.18494-3.37347,8.68328,8.68328,0,0,1,8.78159,8.83494c0,6.10382-5.42854,11.00371-13.65108,18.553Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#6c63ff"
    />
    <path
      d="M409.15748,505.05222h-43a9.51081,9.51081,0,0,1-9.5-9.5v-27a9.5108,9.5108,0,0,1,9.5-9.5h43a9.5108,9.5108,0,0,1,9.5,9.5v27A9.51081,9.51081,0,0,1,409.15748,505.05222Zm-43-44a7.50835,7.50835,0,0,0-7.5,7.5v27a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-27a7.50835,7.50835,0,0,0-7.5-7.5Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#3f3d56"
    />
    <path
      d="M387.65748,496.79056l-2.31545-2.08878c-8.22262-7.54934-13.65109-12.44922-13.65109-18.553a8.68327,8.68327,0,0,1,8.78159-8.83494,9.44536,9.44536,0,0,1,7.185,3.37347,9.445,9.445,0,0,1,7.18495-3.37347,8.68329,8.68329,0,0,1,8.78159,8.83494c0,6.10382-5.42855,11.00371-13.65109,18.553Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#6c63ff"
    />
    <polygon
      points="126.965 512.106 138.087 509.46 133.175 465.302 116.76 469.207 126.965 512.106"
      fill="#ffb8b8"
    />
    <path
      d="M360.77092,693.3538h35.92974a0,0,0,0,1,0,0v13.88195a0,0,0,0,1,0,0H374.65285a13.88193,13.88193,0,0,1-13.88193-13.88193v0A0,0,0,0,1,360.77092,693.3538Z"
      transform="translate(673.4245 1105.72874) rotate(166.61944)"
      fill="#2f2e41"
    />
    <path
      d="M351.87477,671.87692l-24.15528-82.78613,30.18506-7.48731,17.708,87.67627a4.49981,4.49981,0,0,1-4.2771,5.38916l-15.00708.44581c-.04517.001-.09009.00195-.135.00195A4.50057,4.50057,0,0,1,351.87477,671.87692Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#2f2e41"
    />
    <polygon
      points="58.09 512.384 69.522 512.383 74.959 468.287 58.086 468.289 58.09 512.384"
      fill="#ffb8b8"
    />
    <path
      d="M291.46749,697.32116h35.92974a0,0,0,0,1,0,0V711.2031a0,0,0,0,1,0,0H305.34942a13.88193,13.88193,0,0,1-13.88193-13.88193v0A0,0,0,0,1,291.46749,697.32116Z"
      transform="translate(383.1015 1220.29161) rotate(179.99483)"
      fill="#2f2e41"
    />
    <path
      d="M291.90943,683.45554a4.41548,4.41548,0,0,1-1.34448-3.1294l-10.41553-82.585a4.50646,4.50646,0,0,1,4.46729-4.52246l23.24829-.16846h.0332a4.51205,4.51205,0,0,1,4.499,4.43115l1.23584,81.3545a4.497,4.497,0,0,1-4.39576,4.56591l-14.11279,1.34131c-.02173.00049-.04346.00049-.06518.00049A4.46755,4.46755,0,0,1,291.90943,683.45554Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#2f2e41"
    />
    <circle cx="61.06468" cy="197.34791" r="24.56103" fill="#ffb8b8" />
    <path
      d="M264.53443,593.42722C248.554,580.94089,248.72315,566.374,254.176,546.91745c1.53613-5.481,3.19409-11.03613,4.94921-16.91748,8.155-27.32471,17.39795-58.2959,13.88892-83.86816l.49536-.06788-.49536.06788c-.96118-7.00391,1.48877-14.3711,6.72119-20.21192,5.23267-5.8418,12.27564-9.07861,19.35547-8.896,7.06738.18848,13.9397,3.80127,18.85425,9.9126,4.89062,6.08155,7.22485,27.46582,7.91675,30.53076,3.17651,14.0669,5.09375,22.55762,6.46875,36.10254,1.62622,16.01319,21.10254,63.89112,27.30883,71.91993a18.99967,18.99967,0,0,1,3.67676,15.13281,17.80508,17.80508,0,0,1-8.45532,12.13525,101.64833,101.64833,0,0,1-32.70606,12.8711,80.1744,80.1744,0,0,1-15.22705,1.46533C291.07081,607.09421,275.91944,602.32272,264.53443,593.42722Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#6c63ff"
    />
    <path
      d="M366.67182,500.8945a9.37694,9.37694,0,0,0-14.04849-3.06268l-18.61127-10.61977-9.21209,9.736,26.49335,14.628a9.42779,9.42779,0,0,0,15.3785-10.68158Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#ffb8b8"
    />
    <path
      d="M338.3003,508.09323l-33.35718-10.47949a22.96148,22.96148,0,0,1-16.00927-20.82813l-1.43531-32.41113a13.57149,13.57149,0,1,1,27.1233-.18262l-.91748,29.81983,35.5017,14.47851a4.50026,4.50026,0,0,1,1.978,6.76026l-7.85718,11.14306a4.48833,4.48833,0,0,1-5.02661,1.69971Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#2f2e41"
    />
    <path
      d="M257.30885,416.11569c1.12231-6.731,1.08105-13.70752,1.041-20.45459-.01074-1.83154-.02173-3.6626-.01049-5.49023.05224-8.49365.57934-17.4961,4.05175-25.78369,3.7793-9.01954,10.77783-15.53272,18.72119-17.42237,8.95557-2.13281,18.6731,2.582,21.59864,10.34522a15.007,15.007,0,1,1,9.14868,27.96924l-.37525.0332-2.92651-7.60547c-.0166.1333-.03247.26807-.04858.40381-.18628,1.57715-.37891,3.20752-1.68091,4.14941h0c-1.62549,1.17578-3.6499,5.04541-3.05835,8.96582a33.80216,33.80216,0,0,0,1.64209,5.88135,32.2768,32.2768,0,0,1,1.74219,6.47949,11.06488,11.06488,0,0,1-2.07227,8.01368,6.18642,6.18642,0,0,1-4.23852,2.43212l-32.9878,3.41114-1.47485-11.01856-9.3833,11.55664Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#2f2e41"
    />
    <path
      d="M428.82677,711.79528h-192a1,1,0,0,1,0-2h192a1,1,0,0,1,0,2Z"
      transform="translate(-235.82677 -188.20472)"
      fill="#ccc"
    />
  </svg>
);
