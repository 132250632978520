import React from "react";

export default function CardButton({ onClick, label, svg }) {
  return (
    <button onClick={onClick} className="h-80 w-80">
      <div className="w-80 h-80 rounded-xl border p-5 shadow-md bg-slate-50 hover:text-white hover:bg-slate-600 transition-colors my-5s flex flex-col items-center">
        <p className="font-semibold text-2xl my-5">{label}</p>
        {svg}
      </div>
    </button>
  );
}
