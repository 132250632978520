import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import { userAuthStateListener } from "../store/actions/auth";
import AdminScreen from "../screens/AdminScreen";
import DiscussionDetailScreen from "../screens/DiscussionDetailScreen";
import JobDetailScreen from "../screens/JobDetailScreen";
import OfferDetailScreen from "../screens/OfferDetailScreen";
import DiscussionSection from "../sections/DiscussionSection";
import YouthWelfareOfficeScreen from "../screens/YouthWelfareOfficeScreen";
import ContactSection from "../screens/ContactSection";
import ProviderSection from "../sections/ProviderSection";
import JobsScreen from "../screens/JobsScreen";
import ImpressumSection from "../sections/ImpressumSection";
import DataProtectionSection from "../sections/DataProtectionSection";
import UkraineScreen from "../screens/UkraineScreen";
import UkraineDetailScreen from "../screens/UkraineDetailScreen";

export default function Routing() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAuthStateListener());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" exact element={<HomeScreen />} />
      <Route path="/admin" exact element={<AdminScreen />} />
      <Route
        path="/discussion/:id"
        exact
        element={<DiscussionDetailScreen />}
      />
       <Route
        path="/ukraine/:id"
        exact
        element={<UkraineDetailScreen />}
      />
      <Route path="/job/:id" exact element={<JobDetailScreen />} />
      <Route path="/offer/:id" exact element={<OfferDetailScreen />} />
      <Route path="/diskussion" exact element={<DiscussionSection />} />
      <Route
        path="/jugendaemter"
        exact
        element={<YouthWelfareOfficeScreen />}
      />
      <Route path="/kontakt" exact element={<ContactSection />} />
      <Route path="/traeger" exact element={<ProviderSection />} />
      <Route path="/jobs" exact element={<JobsScreen />} />
      <Route path="/ukraine" exact element={<UkraineScreen />} />
      <Route path="/impressum" exact element={<ImpressumSection />} />
      <Route path="/datenschutz" exact element={<DataProtectionSection />} />
    </Routes>
  );
}
