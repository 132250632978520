import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomJobItemType from "../components/general/CustomJobItemType";
import InfoRow from "../components/general/InfoRow";
import Spinner from "../components/general/Spinner";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import FooterSection from "../sections/FooterSection";
import { getJobByDocId } from "../store/actions/jobs";

export default function JobDetailScreen() {
  const job = useSelector((state) => state.jobs.currentJob);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobByDocId(id));
  }, [dispatch, id]);

  return (
    <>
      <NavBar />
      {job !== null && job.docId === id ? (
        <div className="w-full px-4 md:px-16 lg:px-36 flex flex-col items-center pt-32">
          <Title text={job.title} />
          <div>
            <InfoRow label="Berufsabschluss" info={job.qualification} />
            <InfoRow label="Arbeitsbeginn" info={job.workingStart} />
            <InfoRow label="Arbeitsbereich" info={job.workingArea} />
            <InfoRow label="Standort" info={job.location} />
            <InfoRow
              label="Ansprechpartner"
              info="Frank Kröner (Dipl.-Sozialpädagoge)"
            />
            <InfoRow label="Telefonnummer" info="0152/31703300" />
            <InfoRow label="Email" info="projektleitung@jugendhilfe-most.org" />
            <div className="flex flex-row my-4">
              {job.jobType.map((type) => (
                <CustomJobItemType jobType={type} />
              ))}
            </div>
          </div>
          <Title text="Arbeitsbeschreibung" size="small" />
          <p className="whitespace-pre-line mb-10">{job.description}</p>
        </div>
      ) : (
        <div className="w-full h-screen items-center flex flex-col">
          <Spinner />
          <p>Job wird geladen...</p>
        </div>
      )}
      <FooterSection />
    </>
  );
}
