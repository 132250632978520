import React from "react";
import { useDropzone } from "react-dropzone";

export default function FileInput({
  label,
  onDrop,
  accept,
  open,
  enableFilePreview = true,
}) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept,
      onDrop,
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div onClick={open} {...getRootProps()} className="w-full h-56">
      <section
        className={
          isDragActive
            ? "mx-auto cursor-pointer flex w-full h-full max-w-lg flex-col items-center rounded-xl border-2 border-solid  bg-yellow-400 p-6 text-center transition-all"
            : "mx-auto cursor-pointer flex w-full h-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-yellow-400 bg-white p-6 text-center transition-all"
        }
      >
        <div className="h-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={
              isDragActive
                ? "h-10 w-10 text-white"
                : "h-10 w-10 text-yellow-500"
            }
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
        </div>
        <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">
          {label}
        </h2>
        {isDragActive ? (
          <p className="mt-2 text-white tracking-wide transition-colors">
            Loslassen um das Bild abzulegen
          </p>
        ) : (
          <p className="mt-2 text-gray-500 tracking-wide transition-colors">
            Drag’ n’ drop Bilder hier oder klicken um eines auszuwählen
          </p>
        )}
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          {...getInputProps()}
        />
        {enableFilePreview && (
          <aside>
            <ul>{files}</ul>
          </aside>
        )}
      </section>
    </div>
  );
}
