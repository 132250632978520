import cuid from "cuid";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileGrid from "../../components/general/FileGrid";
import FileInput from "../../components/general/FileInput";
import GeneralButton from "../../components/general/GeneralButton";
import SmoothTextInput from "../../components/general/SmoothTextInput";
import Spinner from "../../components/general/Spinner";
import Title from "../../components/general/Title";
import { createOffers, updateOfferUpload } from "../../store/actions/offers";

export default function AddOfferSection({
  setOpenSection,
  update = false,
  titleUpdate = "",
  contentUpdate = "",
  thumbnailUpdate = "",
  tagUpdate = "",
  filesUpdate = [],
  docId,
}) {
  const [title, setTitle] = useState(titleUpdate);
  const [content, setContent] = useState(contentUpdate);
  const [thumbnail, setThumbnail] = useState(thumbnailUpdate);
  const [tag, setTag] = useState(tagUpdate);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromStorageFiles, setFromStorageFiles] = useState(filesUpdate);
  const [error, setError] = useState("");

  const currentUser = useSelector((state) => state.auth.currentUser);

  const dispatch = useDispatch();

  const resetInputs = () => {
    setTitle("");
    setContent("");
    setThumbnail("");
    setTag("");
    setFiles([]);
  };

  const updateOffer = () => {
    let updateDic = {};
    if (title !== titleUpdate) {
      updateDic["title"] = title;
    }
    if (thumbnail !== thumbnailUpdate) {
      updateDic["thumbnail"] = thumbnail;
    }
    if (content !== contentUpdate) {
      updateDic["content"] = content;
    }
    if (tag !== tagUpdate) {
      updateDic["tag"] = tag;
    }
    if (fromStorageFiles !== filesUpdate) {
      updateDic["files"] = fromStorageFiles;
    }
    if (files.length !== 0) {
      updateDic["newFiles"] = files;
    }

    if (Object.keys(updateDic).length !== 0) {
      dispatch(updateOfferUpload(docId, updateDic))
        .then((id) => {
          resetInputs();
          setLoading(false);
          setOpenSection("manageOffer");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setError("Etwas ist schief gelaufen, bitte versuchen Sie es erneut.");
        });
    } else {
      setOpenSection("manageDiscussion");
    }
  };

  const handleUploadOffer = () => {
    setLoading(true);
    //const _files = files.map((file) => file.src);
    if (thumbnail === "") {
      setLoading(false);
      setError("Bitte wählen Sie ein Foto aus.");
      return;
    }

    if (title === "") {
      setLoading(false);
      setError("Bitte wählen Sie einen Titel");
      return;
    }

    if (content === "") {
      setLoading(false);
      setError("Bitte legen Sie den Inhalt fest.");
      return;
    }

    if (tag === "") {
      setLoading(false);
      setError("Bitte legen Sie ein Schlagwort fest");
      return;
    }

    update
      ? updateOffer()
      : dispatch(
          createOffers(
            title,
            content,
            thumbnail,
            currentUser.firstname,
            currentUser.lastname,
            files,
            tag
          )
        )
          .then((id) => {
            resetInputs();
            setLoading(false);
            window.location.assign("/offer/" + id);
          })
          .catch((err) => {
            setLoading(false);
            setError(
              "Etwas ist schiefgelaufen, bitte versuchen Sie es erneut."
            );
          });
  };

  const removeFileById = (id, fromStorage = false) => {
    fromStorageFiles.forEach((file) => console.log(id + " - " + file.src));
    let _newFiles = fromStorage
      ? fromStorageFiles.filter((file) => !(file.src === id))
      : files.filter((file) => !(file.id === id));
    fromStorage ? setFromStorageFiles(_newFiles) : setFiles(_newFiles);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setThumbnail(file);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const onDropFiles = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFiles((prevState) => [...prevState, { id: cuid(), src: file }]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center align-middle">
      <Title text="Neues Angebot erstellen" />
      {update && (
        <>
          <Title text="Bisheriges Foto" size="small" />
          <img
            src={thumbnailUpdate}
            alt=""
            className="w-96 object-cover rounded mb-2"
          />
        </>
      )}
      <FileInput
        onDrop={onDrop}
        accept={"image/*"}
        label="Laden Sie ein Foto hoch"
      />
      <div className="w-full px-4 lg:px-48">
        <SmoothTextInput
          label="Titel des Angebots"
          setValue={setTitle}
          value={title}
          placeholder="Geben Sie einen passenden Titel ein."
        />
        <SmoothTextInput
          label="Schlagwort"
          setValue={setTag}
          value={tag}
          placeholder="Legen Sie ein Schlagwort fest."
        />
        <SmoothTextInput
          label="Inhalt des Angebots"
          setValue={setContent}
          value={content}
          placeholder="Kopieren Sie den Inhalt des Angebots hier hinein."
          multiline={true}
        />
      </div>
      <FileInput
        onDrop={onDropFiles}
        accept={""}
        label="Laden Sie hier Dateien zum Download hoch."
        enableFilePreview={false}
      />
      <FileGrid
        files={fromStorageFiles}
        removeFilesById={removeFileById}
        fromStorage={true}
      />
      <FileGrid files={files} removeFilesById={removeFileById} />

      {error !== "" && <p className="text-red-400 font-semibold">{error}</p>}
      {loading ? (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <GeneralButton
            label="Angebot hinzufügen"
            onClick={() => handleUploadOffer()}
          />
          <GeneralButton
            label="Abbrechen"
            color="blue"
            onClick={() => setOpenSection("home")}
          />
        </>
      )}
    </div>
  );
}
