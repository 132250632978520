import React from "react";

export default function Title({ text, size }) {
  function calculateSize() {
    switch (size) {
      case "large":
        return "text-4xl md:text-5xl lg:text-6xl";
      case "small":
        return "sm:font-semibold md:text-xl lg:text-2xl";
      default:
        return "text-3xl md:text-3xl lg:text-4xl";
    }
  }
  return (
    <p
      className={
        calculateSize() + " font-semibold text-slate-800 text-center my-4"
      }
    >
      {text}
    </p>
  );
}
