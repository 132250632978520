import React from "react";

export default function GeneralButton({ onClick, label, color = "yellow" }) {
  const buttonColor = "bg-" + color + "-400";
  const buttonHoverColor = "hover:bg-" + color + "-700";

  const colorString = buttonColor + " " + buttonHoverColor;
  return (
    <button
      value="button"
      className={
        colorString +
        " px-4 py-2 rounded text-white my-2 transition-colors w-96"
      }
      id="whoobe-ibemp"
      onClick={onClick}
    >
      {label}
    </button>
  );
}
