import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../components/general/Title";
import { MdHome } from "react-icons/md";
import { deleteJobsByDocId, getAllJobs } from "../../store/actions/jobs";
import JobCard from "../../components/general/JobCard";

export default function ManageJobsSection({ setOpenSection, setUpdateProperties }) {
  const jobs = useSelector((state) => state.jobs.allJobs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllJobs());
  }, [dispatch]);

  const deleteJob = (docId) => {
    dispatch(deleteJobsByDocId(docId)).then(() => dispatch(getAllJobs()));
  };

  const updateJob = (job) => {
    setOpenSection("updateJob");
    setUpdateProperties(job);
  };

  return (
    <div className="flex flex-col w-full justify-center items-center px-10 lg:px-10">
      <div className="flex flex-row w-full justify-center items-center">
        <Title text="Stellenausschreibungen verwalten" />
        <button
          className="text-slate-900 hover:text-slate-600 transition-colors"
          onClick={() => setOpenSection("home")}
        >
          <MdHome size={42} />
        </button>
      </div>
      {jobs !== undefined && jobs.length !== 0 ? (
        jobs.map((job) => (
          <JobCard
            jobTitle={job.title}
            location={job.location !== undefined ? job.location : "Gera"}
            qualification={job.qualification}
            time={job.jobType}
            workingArea={job.workingArea}
            type={job.tag}
            workingStart={job.workingStart}
            id={job.docId}
            admin={true}
            onDeleteClick={() => deleteJob(job.docId)}
            onUpdateClick={() => updateJob(job)}
          />
        ))
      ) : (
        <p className="text-slate-900 text-center mx-10 lg:mx-96">
          Es sind derzeit keine Stellenausschreibungen vorhanden!
          <button
            className="ml-3 hover:text-slate-600 transition-colors"
            href=""
            onClick={() => setOpenSection("addJob")}
          >
            Klicke hier um eine zu erstellen.
          </button>
        </p>
      )}
    </div>
  );
}
