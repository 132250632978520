import React from "react";

export default function InfoCard({ title, info, tag, svg, id }) {
  return (
    <a href={"offer/" + id}>
      <div className="overflow-hidden rounded-2xl bg-blue-50 hover:bg-yellow-50 transition-colors p-4 lg:p-12">
        <div className="flex items-center text-blue-600">
          <p className="text-sm font-bold uppercase">{tag}</p>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2 h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>

        <h2 className="mt-4 text-3xl font-semibold text-slate-800">{title}</h2>

        <p className="mt-4 text-lg text-slate-600">{info.slice(0, 500)}</p>

        <div className="mt-12 flex transform items-center justify-center transition-transform duration-150 ease-in-out hover:scale-125">
          <img className="h-auto w-[300px]" src={svg} alt="" />
        </div>
      </div>
    </a>
  );
}
