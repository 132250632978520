import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HorizontalLine from "../components/general/HorizontalLine";
import InfoCard from "../components/general/InfoCard";
import InfoCardRow from "../components/general/InfoCardRow";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import FooterSection from "../sections/FooterSection";
import { getAllOffers } from "../store/actions/offers";

export default function YouthWelfareOfficeScreen() {
  // const ref = useRef();
  // const isVisible = useIsVisible(ref);

  const offers = useSelector((state) => state.offers.allOffers);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isVisible) {
  //     setVisibleSection("youth");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isVisible]);

  useEffect(() => {
    dispatch(getAllOffers());
  }, [dispatch]);

  return (
    <div className="relative overflow-hidden">
      <NavBar />
      <div id="jugendaemter" className="w-full">
        <div className="pt-32">
          <Title text="Aktuelle Angebote" />
          <HorizontalLine />
        </div>
        {/* <div ref={ref}></div> */}
        <div className="px-4 lg:px-10">
          {offers !== undefined && offers !== null && offers.length !== 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
              {offers.map((offer) => (
                <InfoCard
                  info={offer.content}
                  title={offer.title}
                  tag={offer.tag}
                  svg={offer.thumb}
                  id={offer.id}
                  key={offer.id}
                />
              ))}
            </div>
          ) : (
            <p>Es sind derzeit keine Angebote vorhanden</p>
          )}

          <InfoCardRow
            author="Frank Kröner"
            category="Notfall"
            title="Im Notfall"
            info="
Liebe Kollegen,

Sie brauchen Hilfe in einem außergewöhnlichen Fall, der für die Regelangebote nicht mehr erreichbar ist ? Greifen Sie auf unsere Erfahrung in der individuellen Einzelbetreuung zurück und kontaktieren Sie uns. Voraussetzung für eine kurzfristige und erfolgreiche Zusammenarbeit sind die Zurverfügungstellung einer umfassenden Fallinformation, eine konkurrenzfreie Situation und unser Leistungsvertrag. 

Wir stellen Ihnen aber auch ganz allgemein unsere Arbeit vor oder informieren Sie im Rahmen einer Weiterbildung über die aktuellen Entwicklungen im Bereich der Individualpädagogik mit Schwerpunkt auf Auslandsmaßnahmen."
          />
        </div>
      </div>
      <FooterSection />
    </div>
  );
}
