import firebase from "firebase/app";
//import { saveMediaToStorage } from "../../services/random";
import { USER_STATE_CHANGE } from "../constants";

require("firebase/firebase-auth");

export const userAuthStateListener = () => (dispatch) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      dispatch(getCurrentUserData());
      //dispatch(getAllRaves());
    } else {
      dispatch({ type: USER_STATE_CHANGE, currentUser: null, loaded: true });
    }
  });
};

export const getCurrentUserData = () => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("user")
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot((res) => {
        if (res.exists) {
          return dispatch({
            type: USER_STATE_CHANGE,
            currentUser: res.data(),
            loaded: true,
          });
        }
      });
  });

export const login = (email, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error.message);
      });
  });

export const register = (email, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const createUserProfile =
  (username, firstname, lastname) => (dispatch) =>
    new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("user")
        .doc(firebase.auth().currentUser.uid)
        .set({
          displayName: username,
          firstname: firstname,
          lastname: lastname,
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });

// export const updateProfile = (field, value, uid) => (dispatch) =>
//   new Promise((resolve, reject) => {
//     if (field !== "photoURL") {
//       firebase
//         .firestore()
//         .collection("user")
//         .doc(uid)
//         .update({ [field]: value })
//         .then(() => resolve())
//         .catch((err) => {
//           reject();
//
//         });
//     } else {
//       saveMediaToStorage(value, `user/${uid}/photoURL`).then((photo) => {
//         firebase
//           .firestore()
//           .collection("user")
//           .doc(uid)
//           .update({ [field]: photo })
//           .then(() => resolve())
//           .catch((err) => {
//             reject();
//
//           });
//       });
//     }
//   });
export const signOut = () => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({
          type: USER_STATE_CHANGE,
          currentUser: null,
          loaded: false,
        });
      })
      .catch((error) => reject());
  });
