import firebase from "firebase/app";
import { saveMediaToStorage } from "../../services/random";
import uuid from "uuid-random";
import {
  ALL_DISCUSSIONS_CHANGED,
  CURRENT_DISCUSSION_CHANGED,
} from "../constants";
require("firebase/firebase-auth");
require("firebase/firestore");

export const createDiscussion =
  (title, content, thumbnail, authorFirstName, authorLastName, files) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const uploadDate = new Date();
      let storagePostId = uuid() + uploadDate.getMilliseconds();
      saveMediaToStorage(
        thumbnail,
        `discussions/${
          firebase.auth().currentUser.uid
        }/${storagePostId}/thumbnail`
      )
        .then((thumb) => {
          firebase
            .firestore()
            .collection("discussion")
            .doc(storagePostId)
            .set({
              id: storagePostId,
              author: firebase.auth().currentUser.uid,
              authorFirstName,
              authorLastName,
              title,
              content,
              thumb,
              uploadDate,
              updatedDate: uploadDate,
              views: 0,
              files: [],
            })
            .then(() => {
              let index = 0;
              files !== undefined && files.length !== 0
                ? files.forEach((file) => {
                    index++;
                    index === files.length
                      ? addFileToDiscussion(file.src, storagePostId).then(() =>
                          resolve(storagePostId)
                        )
                      : addFileToDiscussion(file.src, storagePostId);
                  })
                : resolve(storagePostId);

              //resolve();
            })
            .catch((e) => reject(e));
        })
        .catch((err) => reject());
    });

export const updateDiscussionUpload = (docId, updateDic) => (dispatch) =>
  new Promise((resolve, reject) => {
    const newFiles = updateDic.newFiles;
    delete updateDic["newFiles"];
    updateDic.thumbnail === null || updateDic.thumbnail === undefined
      ? firebase
          .firestore()
          .collection("discussion")
          .doc(docId)
          .update(updateDic)
          .then(() => {
            let index = 0;
            newFiles !== undefined && newFiles !== null && newFiles.length !== 0
              ? newFiles.forEach((file) => {
                  index++;
                  index === newFiles.length
                    ? addFileToDiscussion(file.src, docId).then(() =>
                        resolve(docId)
                      )
                    : addFileToDiscussion(file.src, docId);
                })
              : resolve(docId);
          })
          .catch((e) => reject(e))
      : saveMediaToStorage(
          updateDic.thumbnail,
          `discussions/${firebase.auth().currentUser.uid}/${docId}/thumbnail`
        )
          .then((thumb) => {
            delete updateDic["thumbnail"];
            firebase
              .firestore()
              .collection("discussion")
              .doc(docId)
              .update({ thumb, ...updateDic })
              .then(() => {
                let index = 0;
                newFiles !== undefined &&
                newFiles !== null &&
                newFiles.length !== 0
                  ? newFiles.forEach((file) => {
                      index++;
                      index === newFiles.length
                        ? addFileToDiscussion(file.src, docId).then(() =>
                            resolve(docId)
                          )
                        : addFileToDiscussion(file.src, docId);
                    })
                  : resolve(docId);
              })
              .catch((e) => reject(e));
          })
          .catch((err) => reject());
  });

export const addFileToDiscussion = (file, id) =>
  new Promise((resolve, reject) => {
    saveMediaToStorage(
      file,
      `discussions/${firebase.auth().currentUser.uid}/${id}/${file.path}`
    ).then((f) => {
      firebase
        .firestore()
        .collection("discussion")
        .doc(id)
        .update({
          files: firebase.firestore.FieldValue.arrayUnion({
            src: f,
            name: file.path,
          }),
        })
        .then(() => resolve())
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  });

export const getAllDiscussions = () => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("discussion")
      .orderBy("uploadDate", "desc")
      .onSnapshot((snapshot) => {
        let discussions = snapshot.docs.map((doc) => {
          const data = doc.data();
          const docId = doc.id;
          return { docId, ...data };
        });
        dispatch({
          type: ALL_DISCUSSIONS_CHANGED,
          allDiscussions: discussions,
        });
      });
  });

export const deleteDiscussionByDocId = (docId) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("discussion")
      .doc(docId)
      .delete()
      .then(() => resolve())
      .catch(() => reject());
  });

export const getDiscussionByDocId = (docId) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("discussion")
      .doc(docId)
      .onSnapshot((snapshot) => {
        dispatch({
          type: CURRENT_DISCUSSION_CHANGED,
          currentDiscussion: { docId, ...snapshot.data() },
        });
      });
  });

export const increaseViewOnDiscussion = (docId) => (dispatch) =>
  new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("discussion")
      .doc(docId)
      .update({ views: firebase.firestore.FieldValue.increment(1) })
      .then(() => resolve())
      .catch((err) => reject(err));
  });
