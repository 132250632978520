import React from "react";

export default function InfoRow({
  label = "",
  info,
  color = "",
  centerText = false,
}) {
  return (
    <div className="flex flex-row justify-between">
      {label !== "" && <p className="font-semibold">{label}:</p>}
      <p className={"pl-2 " + color + (centerText && " text-center")}>{info}</p>
    </div>
  );
}
