import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import { MdPerson, MdHistory, MdDownload, MdTag } from "react-icons/md";
import IconLabelRow from "../components/general/IconLabelRow";
import Spinner from "../components/general/Spinner";
import { getOfferByDocId } from "../store/actions/offers";
import FooterSection from "../sections/FooterSection";

export default function OfferDetailScreen() {
  const offer = useSelector((state) => state.offers.currentOffer);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOfferByDocId(id));
  }, [dispatch, id]);

  return (
    <>
      <NavBar />
      {offer !== null && offer !== undefined && offer.id === id ? (
        <div className="w-full flex items-center flex-col px-4 md:px-20 lg:px-40 pt-12">
          <img
            src={offer.thumb}
            alt=""
            className="rounded h-96 w-full object-cover"
          />
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 justify-center my-2">
            <IconLabelRow
              icon={<MdPerson className="" />}
              label={offer.authorFirstName + " " + offer.authorLastName}
            />
            <IconLabelRow
              icon={<MdHistory />}
              label={new Date(
                offer.uploadDate.seconds * 1000
              ).toLocaleDateString()}
            />
            <IconLabelRow icon={<MdTag />} label={offer.tag} />
          </div>
          <Title text={offer.title} />
          <p className="whitespace-pre-line">{offer.content}</p>
          {offer.files !== undefined && offer.files.length !== 0 && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 py-10">
              {offer.files.map((file) => (
                <a
                  className="bg-yellow-400 text-white rounded px-4 py-2 hover:text-yellow-400 hover:bg-white hover:border hover:border-solid hover:border-yellow-400 transition-colors flex flex-row justify-between align-middle items-center"
                  href={file.src}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.name}
                  <div className="w-1/6 flex justify-end">
                    <MdDownload size={24} />
                  </div>
                </a>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-screen flex flex-col items-center">
          <Spinner />
          <p>Angebot wird geladen...</p>
        </div>
      )}
      <FooterSection />
    </>
  );
}
