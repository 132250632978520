import React from "react";

export default function HorizontalLine({ border = true }) {
  return (
    <hr
      className={
        (border && "border-0 ") +
        "w-96 mx-auto h-1 my-4 bg-gray-100 rounded dark:bg-gray-700"
      }
    />
  );
}
