import React from "react";

export default function NavBarItem({ label, link, setNavbar }) {
  return (
    <li className="text-white hover:text-slate-300">
      <a href={link} onClick={() => setNavbar(false)}>
        {label}
      </a>
    </li>
  );
}
