import React from "react";
import { MdEmail, MdPerson, MdPhone } from "react-icons/md";
import IconLabelRow from "../components/general/IconLabelRow";
import InfoRow from "../components/general/InfoRow";

export default function FooterSection({ marginTop = true }) {
  return (
    <div
      className={
        "w-full bg-blue-300 grid grid-cols-1 lg:grid-cols-3 py-10 px-6 " +
        (marginTop && "mt-10")
      }
    >
      <div className="flex flex-col justify-center items-center">
        <InfoRow
          info="MOST e.V. - Brücken in ein neues Leben"
          color="text-white"
          centerText={true}
        />
        <InfoRow
          info="Salzstr. 143 07551 Gera"
          color="text-white"
          centerText={true}
        />
        <hr className="w-36 border border-white border-dotted my-4" />
      </div>
      <div className="flex flex-col justify-center items-center">
        <a
          className="text-center text-white hover:text-yellow-400 transition-colors text-lg font-semibold"
          href="/kontakt"
        >
          Kontakt
        </a>
        <a
          className="text-center text-white hover:text-yellow-400 transition-colors text-lg font-semibold"
          href="/impressum"
        >
          Impressum
        </a>
        <a
          className="text-center text-white hover:text-yellow-400 transition-colors text-lg font-semibold"
          href="/datenschutz"
        >
          Datenschutz
        </a>
        <hr className="w-36 border border-white border-dotted my-4" />
      </div>
      <div className="flex flex-col justify-center items-center">
        <IconLabelRow
          icon={<MdPerson size={20} />}
          label="Frank Kröner (Dipl.-Sozialpädagoge)"
          color="text-white"
          centerText={true}
        />
        <IconLabelRow
          icon={<MdPhone size={20} />}
          label="0152/31703300"
          color="text-white"
          centerText={true}
        />
        <IconLabelRow
          icon={<MdEmail size={20} />}
          label="projektleitung@jugendhilfe-most.org"
          color="text-white"
          centerText={true}
        />
        <hr className="w-36 border border-white border-dotted my-4" />
      </div>
    </div>
  );
}
