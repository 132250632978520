import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CardButton from "../components/general/CardButton";
import GeneralButton from "../components/general/GeneralButton";
import Title from "../components/general/Title";
import { getCurrentUserData, signOut } from "../store/actions/auth";
import {
  addJobSvg,
  addOfferSvg,
  discussionSvg,
  manageDiscussionSvg,
  manageJobsSvg,
  manageOffersSvg,
} from "../utils/svgs";

export default function AdminHomeSection({ setOpenSection }) {
  const currentUser = useSelector((state) => state.auth.currentUser);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(signOut()).then(() => dispatch(getCurrentUserData()));
  };

  return (
    <div className="flex-col flex items-center justify-center align-middle">
      <Title
        text={
          "Willkommen " + currentUser.firstname + " " + currentUser.lastname
        }
      />
      <GeneralButton label="Logout" onClick={() => handleLogout()} />

      <div className="grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-5 place-items-center mx-20">
        <CardButton
          onClick={() => setOpenSection("addDiscussion")}
          label="Diskussion hinzufügen"
          svg={discussionSvg}
        />
        <CardButton
          onClick={() => setOpenSection("manageDiscussion")}
          label="Diskussionen verwalten"
          svg={manageDiscussionSvg}
        />
        <CardButton
          onClick={() => setOpenSection("addOffer")}
          label="Angebot hinzufügen"
          svg={addOfferSvg}
        />
        <CardButton
          onClick={() => setOpenSection("manageOffers")}
          label="Angebote verwalten"
          svg={manageOffersSvg}
        />
        <CardButton
          onClick={() => setOpenSection("addJob")}
          label="Stellenausschreibung hinzufügen"
          svg={addJobSvg}
        />
        <CardButton
          onClick={() => setOpenSection("manageJobs")}
          label="Stellenausschreibungen verwalten"
          svg={manageJobsSvg}
        />
        <CardButton
          onClick={() => setOpenSection("addUkraine")}
          label="Ukraine Themen hinzufügen"
          svg={addJobSvg}
        />
        <CardButton
          onClick={() => setOpenSection("manageUkraine")}
          label="Ukraine Themen verwalten"
          svg={manageJobsSvg}
        />
      </div>
    </div>
  );
}
