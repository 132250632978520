import React from "react";
import DownloadButton from "../components/general/DownloadButton";
import HorizontalLine from "../components/general/HorizontalLine";
import TextSection from "../components/general/TextSection";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import FooterSection from "./FooterSection";

export default function ProviderSection() {
  // const ref = useRef();
  // const isVisible = useIsVisible(ref);

  // useEffect(() => {
  //   if (isVisible) {
  //     setVisibleSection("provider");
  //   
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isVisible]);
  return (
    <div className="relative overflow-hidden">
      <NavBar />
      <div id="traeger" className="w-full bg-gray-50">
        <div className="pt-32">
          <Title text="Gesamtkonzeption des Trägers MOST e.V. - Brücken in ein neues Leben" />
          <HorizontalLine />
        </div>
        {/* <div ref={ref}></div> */}
        <div className="px-4 lg:px-32">
          <TextSection
            text="Der Name MOST hat nichts mit dem gleichnamigen Getränk zu tun, sondern
        ist in einigen osteuropäischen Sprachen die Vokabel für „Brücke“. Und
        genau das ist unser Ziel: Brücken bauen zwischen Menschen
        unterschiedlicher Kulturen und Brücken ins Leben für junge Menschen, die
        dazu spezielle Hilfe benötigen."
            title="Vorbemerkung"
          />
          <TextSection
            text="Ein großer Teil der stationären Jugendhilfe wird von renommierten Trägern nach bewährten 
Konzepten erbracht und das ist gut so. Allerdings gibt es immer wieder Kinder und Jugendliche, 
die diese Angebote aufgrund ihrer besonderen Bedarfe nicht für sich nutzen können. Sie fallen 
als sogenannte „Systemsprenger“ durch das Netz der klassischen Jugendhilfe und benötigen 
innovative und individuell gestaltete Angebote. An dieser Stelle kommen wir ins Spiel, als 
flexibler, innovativer und kreativer Anbieter von kurzfristigen und langfristigen 
Einzelbetreuungsmaßnahmen mit dem Ziel, auch diesen jungen Menschen eine Brücke in die 
Mitte der Gesellschaft zu bauen. Wir stützen uns dabei auf einen Mitarbeiterpool mit teilweise 
mehr als 25 Jahren Erfahrung in der Individualpädagogik. Daneben haben wir natürlich noch 
andere aktuelle Ziele und Baustellen, in die wir unsere Kompetenz im Bereich der Jugendhilfe 
und im Bereich Osteuropa investieren wollen"
            title="Motiviation"
          />
          <TextSection
            text="Idealerweise sollen fremduntergebrachte Kinder und Jugendliche stabil in einer bedarfsgerechten 
Einrichtung bis zur Selbständigkeit aufwachsen können. Die Praxis zeigt, daß es oft anders ist. 
Die Inobhutnahmen sind voll und werden von jungen Menschen blockiert, für die auch nach 
Monaten keine passende Einrichtung gefunden werden konnte. Oft müssen Kinder, die man 
vorher mit viel Ärger aus den Familien herausgeholt hat, mangels einer Alternative wieder dort 
geparkt werden. Hier kommt unsere Krisenintervention zum Einsatz. Wir bieten dem jungen 
Menschen nicht nur eine fallbezogen gestaltete Zwischenlösung, um dem Jugendamt Zeit für 
eine langfristige Lösung zu bieten, wir analysieren den Bedarf des Kindes bzw. Jugendlichen 
auch im Sinne eines Clearings und geben wertvolle Hinweise, damit nicht irgendein, sondern der 
richtige Patz gefunden werden kann. Idealerweise finden wir selbst eine langfristige Perspektive 
im Träger. Da wir wissen, wie schnell Krisen entstehen können, kann eine Aufnahme notfalls 
innerhalb von 24 Stunden erfolgen."
            title="Krisenintervention/Clearing"
          />
          <TextSection
            text="Der Träger ist immer bemüht, nicht nur kurzfristige, sondern auch nachhaltige Perspektiven 
anzubieten. Dabei stützen wir uns neben einigen wenigen (Einzelbetreuungs-)Einrichtungen und 
langfristigen Auslandsprojekten vor allem auf eine Kombination aus Pflegestelle und massiver 
ambulanter Unterstützung dieser durch eine Fachkraft."
            title="Langfristige Betreeungsperspektiven"
          />
          <TextSection
            text="Der Träger plant am Stammsitz zwei Einrichtungen, zum einen eine Einzelbetreuungseinrichtung 
nach § 35 SGB VIII, zum anderen eine Einzelbetreuungseinrichtung für Mutter und Kind nach § 
19 SGB VIII, da es offensichtlich auch junge Mütter gibt, die nicht gruppenfähig und in einer 
normalen MuKi-Einrichtung überfordert sind."
            title=""
          />
          <TextSection
            text="Unsere langfristigen Auslandsprojekte liegen in Lettland und können unproblematisch und 
kurzfristig die Bedingungen der Brüssel IIb-Verordnung erfüllen. Sie sind nach den 
Erfordernissen des § 38 SGB VIII konzipiert und arbeiten als große Ausnahme mit deutschen 
Fachkräften, die die jungen Menschen in Deutschland übernehmen, mit ihnen ins Ausland gehen 
und mit ihnen wiederkommen, um die Weiterbetreuung zu begleiten.  
Es gibt bei uns keine Verschickungsprojekte in irgendwelche ausländischen Familien, die 
keine Ahnung haben, wie die Sozialisationsbedingungen der jungen Menschen vorher waren und 
die nicht bereit sind, Verantwortung für die Perspektive in Deutschland zu übernehmen. Ein 
Auslandsprojekt ist für uns immer ein Teil eines Gesamtkonzeptes, welches immer mit der 
Selbständigkeit in Deutschland endet."
            title=""
          />
          <TextSection
            text="Das Hauptmodell für langfristige Perspektiven aber sind Pflegestellen, die massiv ambulant 
durch Fachkräfte unterstützt werden oder in denen die Pflegepersonen selbst Fachkräfte sind. 
Das hat mit den bekannten Pflegefamilien, die vom Jugendamt meist nur punktuell unterstützt 
werden, relativ wenig zu tun. Wir sind immer auf der Suche nach Menschen, die bereit sind, 
Kinder und Jugendliche unterschiedlichen Alters aufzunehmen und ihnen einen neuen 
Lebensmittelpunkt zu geben. Das muß nicht immer die Bilderbuch-Familie sein, auch 
charismatische Pflegepersonen können in Verbindung mit guten Plätzen das richtige Konzept 
sein. Auf dieser Basis können wir ganz individuelle Settings schaffen, die wir ambulant soweit 
verstärken, wie es der Bedarf erfordert."
            title=""
          />
          <TextSection
            text="Natürlich wird auch bei uns die Arbeit maßgeblich von der aktuellen Situation und hier 
besonders der kriegerischen Auseinandersetzung in der Ukraine beeinflußt. Wir sehen uns in 
besonderer Weise in der Verantwortung, unsere Kompetenz für die Versorgung der geflüchteten 
ukrainischen Kinder einzusetzen. Gleichzeitig möchten wir unsere Erfahrung mit Osteuropa und 
in der Jugendhilfe dafür einsetzen, die Ukraine auf dem Weg nach Europa mit einem 
Modellprojekt beim (Wieder-)Aufbau eines modernen Sozial- und Jugendhilfesystems zu 
unterstützen."
            title="Osteuropa-Projekte"
          />
          <TextSection
            text="Wir hoffen, daß auch die Zeit für normalen internationalen Jugendaustausch mit allen 
osteuropäischen Ländern inklusive Russlands wieder kommen wird. "
            title=""
          />
          <TextSection
            text="Wir möchten uns weiter mit eigenen Ideen an der Entwicklung von Beteiligungs- und 
Ombudschaftsstrukturen in der Jugendhilfe einsetzen. Der Jugendrechtsnavigator ist ein Projekt, 
welches ehrenamtliches Engagement von Juristen dafür aktivieren soll. 
 
Mobil in die Zukunft – aber wie ohne Führerschein ? Das ist nur eines von vielen Problemen, die 
Careleaver (junge Menschen, die aus der Jugendhilfe in die Selbständigkeit gehen) lösen 
müssen. Hier möchten wir die Solidarität der Gesellschaft anrufen, auch diesen sowieso schon 
benachteiligten jungen Menschen die Chance auf eine mobile Teilhabe zu geben.  
 
Unsere Projekte leben von den Ideen unserer Mitglieder – wir sind immer offen für gute Ideen."
            title="Weitere Projekte in Planung"
          />
          <div className="w-full flex justify-center">
            <DownloadButton
              src="./assets/pdf/konzept_most_eV.pdf"
              name="Gesamtkonzept des Trägers Most e.V."
            />
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
}
