import React from "react";
import { MdDownload } from "react-icons/md";

export default function DownloadButton({ src, name }) {
  return (
    <a
      className="bg-yellow-400 text-white rounded px-4 py-2 hover:text-yellow-400 hover:bg-white hover:border hover:border-solid hover:border-yellow-400 transition-colors flex flex-row justify-between align-middle items-center"
      href={src}
      download
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
      <div className="w-1/6 flex justify-end">
        <MdDownload size={24} />
      </div>
    </a>
  );
}
