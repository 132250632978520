import React from "react";
import DownloadButton from "../components/general/DownloadButton";
import HorizontalLine from "../components/general/HorizontalLine";
import InfoRow from "../components/general/InfoRow";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import FooterSection from "./FooterSection";

export default function ImpressumSection() {
  return (
    <div className="relative overflow-hidden">
      <NavBar />

      <div className="w-full pt-32">
        <Title text="Impressum" />
        <HorizontalLine />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 px-4 lg:px-32 pt-16">
          <div className="lg:w-96">
            <p>Angaben gemäß § 5 TMG:</p>
            <p className="font-semibold pt-4">Vorsitzender</p>
            <p>Frank Kröner (Dipl.-Sozialpädagoge)</p>
            <p className="font-semibold pt-4">Pädagogische Leitung:</p>
            <p>Frank Kröner (Dipl.-Sozialpädagoge)</p>

            <p className="pt-4">MOST e.V. - Brücken in ein neues Leben</p>
            <p>Salzstr. 143</p>
            <p className="pb-4">07551 Gera</p>
            <InfoRow
              label="E-Mail"
              info="projektleitung@jugendhilfe-most.org"
            />
            <InfoRow label="Telefon" info="0152/31703300" />
            <p className="font-semibold pt-4">Inhaltlich Verantwortlicher:</p>
            <p>
              [gemäß § 10 Absatz 3 MDStV] Frank Kröner (Dipl.-Sozialpädagoge)
            </p>
            <p className="font-semibold pt-4">Vereinsregisternummer</p>
            <p>VR 281433</p>
            <p>Amtsgericht Gera</p>
            <p className="my-2">umsatzsteuerbefreit</p>
            <DownloadButton src="" name="Vereinregisterauszug zum Download" />
          </div>
          <div>
            <Title text="Haftungsausschluss (Disclaimer)" size="small" />
            <p className="font-semibold py-2">Haftung für Links</p>
            <p>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <p className="font-semibold py-2">Haftung für Inhalte</p>
            <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
              Entfernung oder Sperrung der Nutzung von Informationen nach den
              allgemeinen Gesetzen bleiben hiervon unberührt. Eine
              diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei
              Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
            <p className="font-semibold py-2">Urheberrecht</p>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
}
