import React, { useState } from "react";
import { MdEmail, MdPerson, MdPhone } from "react-icons/md";
import HorizontalLine from "../components/general/HorizontalLine";
import IconLabelRow from "../components/general/IconLabelRow";
import SmoothTextInput from "../components/general/SmoothTextInput";
import Title from "../components/general/Title";
import NavBar from "../navigation/NavBar";
import emailjs from "@emailjs/browser";
import Spinner from "../components/general/Spinner";
import FooterSection from "../sections/FooterSection";

export default function ContactSection({ setVisibleSection }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const resetFields = () => {
    setName("");
    setEmail("");
    setMessage("");
    setError("");
    setSuccessMessage("");
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const sendEmail = () => {
    setLoading(true);

    if (name === "" || email === "" || message === "") {
      setError("Bitte fühlen Sie alle Felder aus.");
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setError("Bitte geben Sie eine gültige Email an.");
      setLoading(false);
      return;
    }

    const templateParams = { name: name, message: message, replay_to: email };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          resetFields();
          setSuccessMessage("Ihre Kontaktanfrage wurde erfolgreich gesendet!");
          setLoading(false);
        },
        (error) => {
          setError(
            "Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut."
          );
          setLoading(false);
        }
      );
  };

  return (
    <div className="relative">
      <NavBar />
      <div
        id="contact"
        className="w-full px-4 lg:px-96 h-screen flex flex-col items-center align-middle justify-center"
      >
        <div>
          <Title text="Kontaktieren Sie uns" />
          <HorizontalLine />
        </div>
        <div>
          <Title text="Anprechpartner" size="small" />
          <IconLabelRow
            icon={<MdPerson />}
            label="Ansprechpartner: Frank Kröner (Dipl.-Sozialpädagoge)"
          />
          <IconLabelRow
            icon={<MdPhone />}
            label="Telefonnummer: 0152/31703300"
          />
          <IconLabelRow
            icon={<MdEmail />}
            label="Email: projektleitung@jugendhilfe-most.org"
          />
        </div>
        <div className="w-full">
          {/*    <SmoothTextInput
            label={"Ihr Name"}
            setValue={setName}
            value={name}
            placeholder="Max Mustermann"
          />
          <SmoothTextInput
            label={"Ihre Email"}
            setValue={setEmail}
            value={email}
            type="email"
            placeholder="example@email.de"
          />
          <SmoothTextInput
            label={"Ihre Nachricht"}
            setValue={setMessage}
            value={message}
            placeholder="..."
            multiline={true}
          />
          {error !== "" && (
            <p className="text-red-400 text-center font-semibold">{error}</p>
          )}
          {successMessage !== "" && (
            <p className="text-emerald-400 text-center font-semibold">
              {successMessage}
            </p>
          )}
          {loading ? (
            <div className="w-full flex justify-center">
              <Spinner />
            </div>
          ) : (
            <button
              onClick={() => sendEmail()}
              className="inline-block w-full my-4 px-4 py-2 text-center font-semibold text-white bg-blue-300 hover:text-blue-300 hover:bg-white hover:border hover:border-blue-300 transition-colors rounded-md shadow"
            >
              Jetzt kontaktieren
            </button>
          )}*/}
        </div>
      </div>
      <FooterSection marginTop={false} />
    </div>
  );
}
