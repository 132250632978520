import firebase from "firebase/app";
//import Resizer from "react-image-file-resizer";
require("firebase/firebase-storage");

export const saveMediaToStorage = (media, path) =>
  new Promise((resolve, reject) => {
    firebase
      .storage()
      .ref(path)
      .put(media)
      .then(() => {
        firebase
          .storage()
          .ref(path)
          .getDownloadURL()
          .then((url) => resolve(url))
          .catch((err) => reject());
      })
      .catch((err) => reject());
  });

// export const resizeFile = (file) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       1000,
//       1000,
//       "JPEG",
//       100,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "file"
//     );
//   });

// export const handleImageAsFile = async (e, setImage) => {
//   const file = e.target.files[0];
//   const image = await resizeFile(file);
//   setImage(image);
// };
