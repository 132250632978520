import React from "react";

export default function IconLabelRow({
  icon,
  label,
  color = "text-slate-400",
  centerText = false,
}) {
  return (
    <div className={"flex flex-row items-center mx-5 text-lg " + color}>
      {icon}
      <p className={centerText ? "text-center" : undefined}>{label}</p>
    </div>
  );
}
