import React from "react";

export default function SmoothTextInput({
  label,
  value,
  setValue,
  multiline,
  placeholder = "",
  type = "text",
}) {
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="flex flex-col justify-start text-slate-900 w-full my-3">
      <p className="font-semibold my-1">{label}</p>
      {multiline ? (
        <textarea
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className="rounded border-solid border-2 text-lg px-4 py-2 w-full"
        />
      ) : (
        <input
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          className="rounded border-solid border-2 text-lg px-4 py-2 w-full"
        />
      )}
    </div>
  );
}
