import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

export default function InfoCardRow({
  title,
  author,
  category,
  info,
  admin = false,
  onDeleteClick,
  onUpdateClick,
}) {
  return (
    <div className="flex items-center justify-center py-2 w-full">
      <div className="rounded-xl border p-5 shadow-md w-full bg-white">
        <div className="flex w-full items-center justify-between border-b pb-3">
          <div className="flex items-center space-x-3">
            <div className="h-8 w-8 rounded-full bg-slate-400 bg-[url('https://www.jugendhilfe-most.org/-_-/res/4ead0bb2-6f05-418b-a267-41c8a9eaf986/images/files/4ead0bb2-6f05-418b-a267-41c8a9eaf986/9090d052-b033-46c7-9fe7-a7a2903b3fe7/768-768/60e1218fba50730ff040bea0cdce57cad36fb66f')]"></div>
            <div className="text-lg font-bold text-slate-700">{author}</div>
          </div>
          <div className="flex flex-row">
            <div className="flex items-center space-x-8">
              <button className="rounded-2xl border bg-blue-400 px-3 py-1 text-xs font-semibold text-white">
                {category}
              </button>
            </div>
            {admin === true && (
              <button onClick={onUpdateClick}>
                <MdEdit
                  className="text-yellow-400 hover:text-yellow-700 transition-colors"
                  size={24}
                />
              </button>
            )}
            {admin === true && (
              <button onClick={onDeleteClick}>
                <MdDelete
                  className="text-blue-400 hover:text-blue-700 transition-colors"
                  size={24}
                />
              </button>
            )}
          </div>
        </div>

        <div className="mt-4 mb-6">
          <div className="mb-3 text-xl font-bold">{title}</div>
          <div className="text-sm text-neutral-600 break-words">
            {info !== undefined && info.slice(0, 900)}
            {info !== undefined && info.length > 900 && "..."}
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between text-slate-500">
            <div className="flex space-x-4 md:space-x-8"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
